import { Flex, Card, CardBody, Button } from '@chakra-ui/react';
import TripForm from '../../forms/trip';
import { LuArrowRight } from 'react-icons/lu';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, date, number } from 'yup';
import { ITrip } from '../../../types/ITrip';
import { useTripContext } from '../../../context/TripContext';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const defaultValues: ITrip = {
	status: 'Draft',
	agency: '',
	name: '',
	dateFrom: new Date(),
	dateTo: new Date(),
	people: 0,
	pilot: '',
	pilotPhone: '',
	notes: ''
};

const schema = object({
	status: string().required(),
	agency: string().required(),
	name: string().required(),
	dateFrom: date().required(),
	dateTo: date().required(),
	people: number().required(),
	pilot: string().nullable(),
	pilotPhone: string().nullable(),
	notes: string().nullable()
});

const TripData = ({ setPage }: { setPage: any }) => {
	let [searchParams, setSearchParams] = useSearchParams();
	const { tripData, setTripData } = useTripContext();
	const { register, handleSubmit, setValue } = useForm<ITrip | any>({
		defaultValues,
		resolver: yupResolver(schema),
		mode: 'onSubmit' // default is "onSubmit"
	});

	const submit = (values: ITrip) => {
		setTripData({ ...tripData, ...{ step: 1, ...values } });
		setPage();
	};

	useEffect(() => {
		const paramsDateFrom = searchParams.get('dateFrom');
		const paramsDateTo = searchParams.get('dateTo');
		if (paramsDateFrom) setValue('dateFrom', new Date(paramsDateFrom));
		if (paramsDateTo) setValue('dateTo', new Date(paramsDateTo));
		const dateFromInput = document.getElementById('tipDateFrom') as any;
		dateFromInput.value = paramsDateFrom;
		const dateToInput = document.getElementById('tipDateTo') as any;
		dateToInput.value = paramsDateTo;
	}, [searchParams]);

	return (
		<Flex
			display={tripData.step === 0 ? 'block' : 'none'}
			minW={'300px'}
			maxW={'520px'}
			w={'100%'}>
			<form onSubmit={handleSubmit(submit)}>
				<TripForm register={register} setValue={setValue} />
				<Flex justifyContent={'start'} mt={5}>
					<Button
						type="submit"
						rightIcon={<LuArrowRight />}
						colorScheme={'blue'}>
						Dalej
					</Button>
				</Flex>
			</form>
		</Flex>
	);
};

export default TripData;
