import { CircularProgress, Flex, Container, Text } from '@chakra-ui/react';

export default function Loading() {
	return (
		<Container maxW={'5xl'} mt={'32px'}>
			<Flex
				flexFlow={'column'}
				justifyContent={'center'}
				alignItems={'center'}
				pt={'64px'}>
				<CircularProgress isIndeterminate color="blue.700" size={'120px'} />
				<Text fontWeight={500} mt={'24px'}>
					Loading ...
				</Text>
			</Flex>
		</Container>
	);
}
