import { useQuery } from '@apollo/client';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_TRIP } from '../../graphql/queries/GetTrip';
import {
	AspectRatio,
	Box,
	Button,
	Container,
	Flex,
	Heading,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useToast
} from '@chakra-ui/react';
import Loading from '../../components/loading/Loading';
import BackBreadcrumb from '../../components/breadcrumbs/BackBreadcrumb';
import { parseDate } from '../../utils/parseDate';
import {
	LuBedSingle,
	LuCheck,
	LuCheckCheck,
	LuChevronDown,
	LuMail,
	LuMapPin,
	LuPencil,
	LuPhone,
	LuTrash
} from 'react-icons/lu';
import { ITripPlan, TripDay } from '../../types/ITrip';
import { DirectionsRenderer, GoogleMap, Marker } from '@react-google-maps/api';
import TableStatus from '../../components/trip/tableStatus';
import { StatusesEnum } from '../../enums/Statuses';
import getTripStatus from '../../utils/getTripStatus';
import { useMutation } from '@apollo/client';
import { UPDATE_TRIP_STATUS } from '../../graphql/mutations/Trip';

const TripDayPlan = ({ plan }: { plan: ITripPlan }) => {
	return (
		<Flex mt={3} alignItems={'center'} gap={2}>
			<Box fontSize={'2xl'}>
				<LuMapPin />
			</Box>
			<Box>
				<Text>{plan.place}</Text>
				<Text>{plan.address}</Text>
			</Box>
		</Flex>
	);
};

const TripDayEl = ({ data, dayNo }: { data: TripDay; dayNo: number }) => {
	const totalDistance = Math.ceil(data.distance / 1000);
	return (
		<Flex flexFlow={'column'} mt={3}>
			<Heading color={'gray.500'} fontSize={'xl'}>
				Dzień {dayNo} - {totalDistance} km
			</Heading>
			<Flex>
				<Flex flex={1} flexFlow={'column'}>
					{' '}
					{data.plan.map((plan: ITripPlan, index: number) => (
						<TripDayPlan key={index} plan={plan} />
					))}
				</Flex>
				<Flex
					flex={1}
					flexFlow={'column'}
					alignItems={'start'}
					justifyContent={'start'}>
					{data.hotel ? (
						<Flex alignItems={'center'} gap={2} mt={3}>
							<Text fontSize={'2xl'}>
								<LuBedSingle />
							</Text>
							<Text>{data.hotel}</Text>
						</Flex>
					) : (
						''
					)}
					{data.notes ? (
						<Flex alignItems={'center'} gap={2} mt={3}>
							<Text fontSize={'2xl'}>
								<LuPencil />
							</Text>
							<Text>{data.notes}</Text>
						</Flex>
					) : (
						''
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};

const TripDetails = () => {
	const toast = useToast();
	const [tripStatus, setTripStatus] = useState<StatusesEnum | null>();
	const [directions, setDirections] = useState<any[]>([]);
	const [destinations, setDestinations] = useState<any[]>([]);
	const navigate = useNavigate();
	const params = useParams();
	const id = params.id;
	const [updateTripStatus] = useMutation(UPDATE_TRIP_STATUS);
	const { loading, error, data, refetch } = useQuery(GET_TRIP, {
		variables: { id }
	});
	const countTotalDistance = () => {
		let total = 0;
		const days = [...data.trip.days];
		days.map((day: TripDay) => (total += day.distance));
		return Math.ceil(total / 1000);
	};
	const getDirections = () => {
		const directionsService = new google.maps.DirectionsService();
		const origin = destinations[0];
		const destination = destinations[destinations.length - 1];
		let distance = 0; // Initialize distance

		const processRoute = (request: any) => {
			return new Promise((resolve) => {
				directionsService.route(request, (result: any, status: any) => {
					if (status === google.maps.DirectionsStatus.OK) {
						// Update total distance
						distance += result.routes[0].legs[0].distance.value;
						resolve(result);
					} else {
						console.error(`Error fetching directions ${result}`);
						resolve(null);
					}
				});
			});
		};
		const requests = [];
		for (let i = 0; i < destinations.length - 1; i++) {
			const request = {
				origin: i === 0 ? origin : destinations[i],
				destination:
					i === destinations.length - 2 ? destination : destinations[i + 1],
				travelMode: google.maps.TravelMode.DRIVING
			};
			requests.push(request);
		}
		Promise.all(requests.map((req) => processRoute(req))).then((directions) => {
			setDirections(directions.filter((direction) => direction !== null));
		});
	};
	useEffect(() => {
		if (data) {
			if (data.trip === null) navigate('/');
			else {
				if (data.trip.status) {
					const status: StatusesEnum = getTripStatus(data.trip);
					setTripStatus(status);
				}
				const newDestinations: any[] = [];
				let plan: ITripPlan[] = [];
				data.trip.days.forEach((day: TripDay) => {
					day.plan.forEach((el: ITripPlan) => plan.push(el));
				});
				plan.map((item: ITripPlan) => {
					const { lat, lng } = item;
					if (lat !== 0 && lng !== 0)
						newDestinations.push({
							lat,
							lng
						});
				});
				setDestinations(newDestinations);
			}
		}
	}, [data]);
	useEffect(() => {
		getDirections();
	}, [destinations]);
	const setAsConfirmed = async () => {
		try {
			const tripID = data.trip._id;
			const status = 'Confirmed';
			await updateTripStatus({
				variables: {
					tripID,
					status
				}
			});
			const trip = { ...data.trip };
			trip.status = 'Confirmed';
			const newTripStatus: StatusesEnum = getTripStatus(trip);
			setTripStatus(newTripStatus);
			toast({
				title: 'Sukces!',
				description: 'Wyjazd został potwierdzony!',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'bottom-right'
			});
		} catch (error) {
			toast({
				title: 'Błąd!',
				description: 'Nie udało się potwierdzić wyjazdu.',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'bottom-right'
			});
		}
	};
	if (loading) return <Loading />;
	if (error) navigate('/');
	return (
		<Container maxW={'6xl'} pb={12}>
			<Flex flexFlow={'column'} gap={3} mb={'24px'}>
				<BackBreadcrumb page="trips" name="Wyjazdy" />
				<BackBreadcrumb page="trips/calendar" name="Kalendarz" />
			</Flex>
			<Flex justifyContent={'space-between'} alignItems={'start'}>
				<Box>
					<Heading>{data.trip.name}</Heading>
					<Flex alignItems={'center'} gap={2} mt={'8px'} mb={'32px'}>
						<Text fontWeight={'600'} color={'gray.600'}>
							{parseDate(data.trip.dateFrom)} - {parseDate(data.trip.dateTo)}
						</Text>
					</Flex>
				</Box>
				{tripStatus !== null &&
					(tripStatus === StatusesEnum.Draft ||
						tripStatus === StatusesEnum.Confirmed) && (
						<Menu>
							<MenuButton
								as={Button}
								rightIcon={<LuChevronDown />}
								bg={'transparent'}>
								Akcje
							</MenuButton>
							<MenuList>
								{tripStatus === StatusesEnum.Draft && (
									<MenuItem icon={<LuCheck />} onClick={setAsConfirmed}>
										Oznacz jako Potwierdzony
									</MenuItem>
								)}
								{/* <MenuItem icon={<LuPencil />}>Edytuj Informację</MenuItem> */}
								<MenuItem
									icon={<LuPencil />}
									onClick={() => navigate(`/trip/update/${data.trip._id}`)}>
									Edytuj Trasę
								</MenuItem>
								{/* <MenuItem icon={<LuTrash />}>Usuń</MenuItem> */}
							</MenuList>
						</Menu>
					)}
				{tripStatus !== null && tripStatus === StatusesEnum.Active && (
					<Button colorScheme="red">Zakończ</Button>
				)}
			</Flex>
			<Flex flexFlow={{ base: 'column', lg: 'row' }} gap={2}>
				{/* <Card flex={1}>
					<CardBody>
						<VStack
							divider={<StackDivider borderColor="gray.200" />}
							spacing={4}
							align="stretch">
							<FormControl>
								<FormLabel fontWeight={800} color={'gray.500'}>
									PILOT
								</FormLabel>
								<Text>{data.trip.pilot || ''}</Text>
								<Text>{data.trip.pilotPhone || ''}</Text>
							</FormControl>
							<FormControl>
								<FormLabel fontWeight={800} color={'gray.500'}>
									KIEROWCA
								</FormLabel>
								<Text>
									{`${data.trip.driver?.firstName} ${data.trip.driver?.lastName}` ||
										'-'}
								</Text>
								<Text>{data.trip.driver?.phone || ''}</Text>
								<Text>{data.trip.driver?.email || ''}</Text>
							</FormControl>
							<FormControl>
								<FormLabel fontWeight={800} color={'gray.500'}>
									POJAZD
								</FormLabel>
								<Text>
									{`${data.trip.car?.brand} ${data.trip.car?.carModel}` || ''}
								</Text>
								<Text>{data.trip.car?.registrationNo || ''}</Text>
							</FormControl>
							<FormControl>
								<FormLabel fontWeight={800} color={'gray.500'}>
									AGENCJA
								</FormLabel>
							</FormControl>
						</VStack>
					</CardBody>
				</Card> */}
				<Flex flexFlow={'column'} w={'full'}>
					<Heading color={'gray.600'} fontSize={'3xl'}>
						Informacje
					</Heading>
					<Flex flexFlow={'column'} gap={2}>
						{typeof tripStatus !== 'undefined' && tripStatus !== null ? (
							<Fragment>
								<Text fontWeight={800} color={'gray.500'} mt={3}>
									Status
								</Text>
								<Flex>
									<TableStatus status={tripStatus} typeBadge="regular" />
								</Flex>
							</Fragment>
						) : (
							''
						)}
						<Fragment>
							<Text fontWeight={800} color={'gray.500'} mt={3}>
								Pilot
							</Text>
							<Text>{data.trip.pilot || 'Brak danych'}</Text>
							{data.trip.pilotPhone ? (
								<Flex gap={1}>
									<LuPhone />
									{data.trip.pilotPhone}
								</Flex>
							) : (
								''
							)}
						</Fragment>
						<Fragment>
							<Text mt={2} fontWeight={800} color={'gray.500'}>
								Kierowca
							</Text>
							{data.trip.driver ? (
								<Fragment>
									<Text>
										{data.trip.driver.firstName} {data.trip.driver.lastName}
									</Text>
									{data.trip.driver.phone ? (
										<Flex gap={1}>
											<LuPhone />
											{data.trip.driver.phone}
										</Flex>
									) : (
										''
									)}
									{data.trip.driver.mail ? (
										<Flex gap={1}>
											<LuMail />
											{data.trip.driver.mail}
										</Flex>
									) : (
										''
									)}
								</Fragment>
							) : (
								<Text>Brak danych</Text>
							)}
						</Fragment>
						<Fragment>
							<Text mt={2} fontWeight={800} color={'gray.500'}>
								Pojazd
							</Text>
							{data.trip.car ? (
								<Fragment>
									<Text>
										{data.trip.car.brand} {data.trip.car.carModel}
									</Text>
									<Text>{data.trip.car.registrationNo}</Text>
								</Fragment>
							) : (
								<Text>Brak danych</Text>
							)}
						</Fragment>
						<Fragment>
							<Text mt={2} fontWeight={800} color={'gray.500'}>
								Biuro podrózy
							</Text>
							{data.trip.agency ? (
								<Fragment>{data.trip.agency.name}</Fragment>
							) : (
								<Text>Brak danych</Text>
							)}
						</Fragment>
					</Flex>
					<Heading color={'gray.600'} fontSize={'3xl'} mt={5}>
						Trasa
					</Heading>
					<Flex mt={2}>
						<Heading color={'gray.500'} fontSize={'xl'}>
							Odległóść - {countTotalDistance()} km
						</Heading>
					</Flex>
					<Flex flexFlow={'column'} gap={2} mt={3}>
						{data.trip.days.map((day: TripDay, index: number) => (
							<TripDayEl key={index} data={day} dayNo={index + 1} />
						))}
					</Flex>
					<Heading color={'gray.600'} fontSize={'3xl'} mt={5}>
						Mapa
					</Heading>
					{destinations.length > 0 ? (
						<AspectRatio ratio={16 / 9} mt={3}>
							<GoogleMap zoom={1} center={destinations[0]}>
								{destinations.map((destination, index) => (
									<Marker key={index} position={destination} />
								))}
								{directions.map((direction, index) => (
									<DirectionsRenderer key={index} directions={direction} />
								))}
							</GoogleMap>
						</AspectRatio>
					) : (
						'Brak trasy'
					)}
				</Flex>
			</Flex>
		</Container>
	);
};

export default TripDetails;
