import React from 'react';
import {
	Box,
	Flex,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Avatar,
	AvatarBadge,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Heading,
	Text
} from '@chakra-ui/react';
import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import {
	LuSearch,
	LuBell,
	LuUsers,
	LuMessagesSquare,
	LuX,
	LuUser
} from 'react-icons/lu';
import { useAuth } from '../hooks/useAuth';

const AppLayout: React.FC = () => {
	const { isAuthenticated } = useAuth();
	if (!isAuthenticated) return <Navigate to={'/login'} />;
	return (
		<Box minH="100vh">
			<Sidebar />
			<Box
				ml={{ base: 0, md: 64 }}
				minH={'100vh'}
				bg={'gray.50'}
				overflowY={'auto'}>
				<Flex
					w={'full'}
					justifyContent={'space-between'}
					alignItems={'center'}
					gap={4}
					mb={'64px'}
					p={3}
					bg={'white'}>
					{/* <Input placeholder="Search..." maxW={'250px'} /> */}
					<Flex>
						<InputGroup
							border={0}
							borderWidth={0}
							outline={0}
							borderColor={'transparent'}
							size={'md'}
							_hover={{
								borderColor: 'transparent',
								outline: 0,
								borderWidth: 0
							}}
							_active={{
								borderColor: 'transparent',
								outline: 0,
								borderWidth: 0
							}}
							_focus={{
								borderColor: 'transparent',
								outline: 0,
								borderWidth: 0
							}}>
							<InputLeftElement pointerEvents="none">
								<LuSearch color="gray.300" />
							</InputLeftElement>
							<Input
								type="text"
								placeholder="Szukaj"
								border={0}
								borderWidth={0}
								outline={0}
								borderColor={'transparent'}
								_hover={{
									borderColor: 'transparent',
									outline: 0,
									borderWidth: 0,
									boxShadow: 'none'
								}}
								_active={{
									borderColor: 'transparent',
									outline: 0,
									borderWidth: 0,
									boxShadow: 'none'
								}}
								_focus={{
									borderColor: 'transparent',
									outline: 'none',
									borderWidth: 0,
									boxShadow: 'none'
								}}
							/>
						</InputGroup>
					</Flex>
					<Flex pr={4} alignItems={'center'} gap={6}>
						<Menu>
							<MenuButton>
								<Icon fontSize={'2xl'} as={LuBell} color={'gray.500'} />
							</MenuButton>
							<MenuList>
								<Heading fontSize={'md'} px={3} pb={3} pt={1}>
									Powiadomienia
								</Heading>
								<MenuItem>
									<Flex
										alignItems={'center'}
										justifyContent={'space-between'}
										w={'full'}>
										<Flex alignItems={'center'}>
											<Flex
												p={2}
												borderRadius={20}
												bg={'gray.200'}
												fontSize={'lg'}
												fontWeight={700}
												justifyContent={'center'}
												alignItems={'center'}>
												<LuMessagesSquare />
											</Flex>
											<Text fontSize={'sm'} ml={2}>
												Lorem ipsum
											</Text>
										</Flex>
										<LuX />
									</Flex>
								</MenuItem>
								<MenuItem>
									<Flex
										alignItems={'center'}
										justifyContent={'space-between'}
										w={'full'}>
										<Flex alignItems={'center'}>
											<Flex
												p={2}
												borderRadius={20}
												bg={'gray.200'}
												fontSize={'lg'}
												fontWeight={700}
												justifyContent={'center'}
												alignItems={'center'}>
												<LuMessagesSquare />
											</Flex>
											<Text fontSize={'sm'} ml={2}>
												Lorem ipsum
											</Text>
										</Flex>
										<LuX />
									</Flex>
								</MenuItem>
								<MenuItem>
									<Flex
										alignItems={'center'}
										justifyContent={'space-between'}
										w={'full'}>
										<Flex alignItems={'center'}>
											<Flex
												p={2}
												borderRadius={20}
												bg={'gray.200'}
												fontSize={'lg'}
												fontWeight={700}
												justifyContent={'center'}
												alignItems={'center'}>
												<LuMessagesSquare />
											</Flex>
											<Text fontSize={'sm'} ml={2}>
												Lorem ipsum
											</Text>
										</Flex>
										<LuX />
									</Flex>
								</MenuItem>
							</MenuList>
						</Menu>
						<Menu>
							<MenuButton>
								<Icon fontSize={'2xl'} as={LuUsers} color={'gray.500'} />
							</MenuButton>
							<MenuList>
								<Heading fontSize={'md'} px={3} pb={3} pt={1}>
									Kontakty
								</Heading>
								<MenuItem>
									<Flex
										alignItems={'center'}
										justifyContent={'space-between'}
										w={'full'}>
										<Flex alignItems={'center'}>
											<Flex
												p={2}
												borderRadius={20}
												bg={'gray.200'}
												fontSize={'lg'}
												fontWeight={700}
												justifyContent={'center'}
												alignItems={'center'}>
												<LuUser />
											</Flex>
											<Text fontSize={'sm'} ml={2}>
												Jan Kowalski
											</Text>
										</Flex>
									</Flex>
								</MenuItem>
								<MenuItem>
									<Flex
										alignItems={'center'}
										justifyContent={'space-between'}
										w={'full'}>
										<Flex alignItems={'center'}>
											<Flex
												p={2}
												borderRadius={20}
												bg={'gray.200'}
												fontSize={'lg'}
												fontWeight={700}
												justifyContent={'center'}
												alignItems={'center'}>
												<LuUser />
											</Flex>
											<Text fontSize={'sm'} ml={2}>
												Jan Kowalski
											</Text>
										</Flex>
									</Flex>
								</MenuItem>
								<MenuItem>
									<Flex
										alignItems={'center'}
										justifyContent={'space-between'}
										w={'full'}>
										<Flex alignItems={'center'}>
											<Flex
												p={2}
												borderRadius={20}
												bg={'gray.200'}
												fontSize={'lg'}
												fontWeight={700}
												justifyContent={'center'}
												alignItems={'center'}>
												<LuUser />
											</Flex>
											<Text fontSize={'sm'} ml={2}>
												Jan Kowalski
											</Text>
										</Flex>
									</Flex>
								</MenuItem>
							</MenuList>
						</Menu>
						<Menu>
							<MenuButton>
								<Avatar size={'xs'}>
									<AvatarBadge borderColor="papayawhip" bg="tomato" />
								</Avatar>
							</MenuButton>
							<MenuList justifyContent={'center'}>
								<MenuItem
									justifyContent={'center'}
									textAlign={'center'}
									fontWeight={700}
									fontSize={'sm'}>
									Wyloguj
								</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
				</Flex>
				<Outlet />
			</Box>
		</Box>
	);
};

export default AppLayout;
