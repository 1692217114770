import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';

const CarForm = ({ register }: any) => {
	return (
		<Flex flexFlow={'column'} gap={4}>
			<FormControl isRequired>
				<FormLabel>Rejestracja</FormLabel>
				<Input {...register('registrationNo')} />
			</FormControl>
			<FormControl isRequired>
				<FormLabel>Marka</FormLabel>
				<Input {...register('brand')} />
			</FormControl>
			<FormControl isRequired>
				<FormLabel>Model</FormLabel>
				<Input {...register('carModel')} />
			</FormControl>
			<FormControl>
				<FormLabel>VIN</FormLabel>
				<Input {...register('vin')} />
			</FormControl>
			<FormControl>
				<FormLabel>Liczba miejsc</FormLabel>
				<Input type="number" min={0} {...register('seats')} />
			</FormControl>
			<FormControl>
				<FormLabel>Rok produkcji</FormLabel>
				<Input type="number" {...register('yearOfProduction')} />
			</FormControl>
			<FormControl>
				<FormLabel>Silnik</FormLabel>
				<Input {...register('engine')} />
			</FormControl>
		</Flex>
	);
};

export default CarForm;
