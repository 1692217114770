import {
	Box,
	Divider,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	Card,
	CardBody
} from '@chakra-ui/react';
import {
	LuHeart,
	LuMoreVertical,
	LuPencil,
	LuTrash,
	LuDownload
} from 'react-icons/lu';
import { FaFile } from 'react-icons/fa';
import { IFile } from '../../types/Files';

interface IProps {
	file: IFile;
	onDelete: (path: string) => void;
	download: (path: string) => void;
}

export default function FilesGridItem({ file, onDelete, download }: IProps) {
	const deleteFile = () => {
		onDelete(file.path);
	};
	const downloadFile = () => {
		download(file.path);
	};
	return (
		<Card bg={'whiteAlpha.700'} _hover={{ background: 'white' }}>
			<CardBody>
				<Flex justifyContent={'space-between'} alignItems={'center'}>
					<LuHeart />
					<Menu>
						<MenuButton>
							<LuMoreVertical />
						</MenuButton>
						<MenuList minW={'150px'}>
							{/* <MenuItem
								fontSize={'md'}
								icon={<LuPencil fontWeight={'bold'} fontSize={'1rem'} />}>
								Zmień nazwę
							</MenuItem> */}
							<MenuItem
								fontSize={'sm'}
								icon={<LuDownload fontWeight={'bold'} fontSize={'1rem'} />}
								onClick={downloadFile}>
								Pobierz
							</MenuItem>
							<MenuItem
								fontSize={'sm'}
								color={'red'}
								icon={<LuTrash fontWeight={'bold'} fontSize={'1rem'} />}
								onClick={deleteFile}>
								Usuń
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
				<Box mt={7}>
					<FaFile fontSize={'3rem'} color={'orange'} />
					{/* <Text mt={3} fontSize={'sm'} fontWeight={'500'}>
						Skan
					</Text> */}
					<Text fontSize={'sm'}>{file.fileName}</Text>
				</Box>
				<Divider my={4} />
				<Flex
					flexFlow={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
					color={'gray.600'}
					gap={2}>
					<Text fontSize={'sm'}>{file.size}</Text>
					<Text fontSize={'xs'}>Utworzono {file.date}</Text>
				</Flex>
			</CardBody>
		</Card>
	);
}
