import { Fragment } from 'react';
import TableComponent from '../components/table';

const columns = [
	{ name: 'Method', key: 'method' },
	{ name: 'Status', key: 'status' },
	{ name: 'Path', key: 'path' },
	{ name: 'Event', key: 'event' },
	{ name: 'IP', key: 'ip' },
	{ name: 'Date', key: 'date' }
];

const data: any[] = [];

export default function LogsView() {
	return (
		<Fragment>
			<TableComponent header="Logi" columns={columns} data={data} />
		</Fragment>
	);
}
