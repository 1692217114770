import {
	Container,
	Flex,
	FormControl,
	FormLabel,
	Input
} from '@chakra-ui/react';

const FormDriver = ({ register }: any) => {
	return (
		<Flex flexFlow={'column'} gap={4}>
			<Flex flexFlow={{ base: 'column', md: 'row' }} gap={4}>
				<FormControl isRequired>
					<FormLabel>Imie</FormLabel>
					<Input {...register('firstName')} />
				</FormControl>
				<FormControl isRequired>
					<FormLabel>Nazwisko</FormLabel>
					<Input {...register('lastName')} />
				</FormControl>
			</Flex>
			<FormControl>
				<FormLabel>Telefon</FormLabel>
				<Input {...register('phone')} />
			</FormControl>
			<FormControl>
				<FormLabel>Email</FormLabel>
				<Input {...register('email')} />
			</FormControl>
		</Flex>
	);
};

export default FormDriver;
