import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Button
} from '@chakra-ui/react';
import React from 'react';

const AlertWindow = ({
	isOpen,
	onOpen,
	onClose,
	title,
	subTitle,
	onDelete
}: any) => {
	const cancelRef = React.useRef<HTMLButtonElement>(null);

	return (
		<AlertDialog
			isOpen={isOpen}
			onClose={onClose}
			leastDestructiveRef={cancelRef}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{title}
					</AlertDialogHeader>
					<AlertDialogBody>{subTitle}</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							Anuluj
						</Button>
						<Button colorScheme="red" onClick={onDelete} ml={3}>
							Usuń
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

export default AlertWindow;
