import {
	Container,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel
} from '@chakra-ui/react';
import BackBreadcrumb from '../../components/breadcrumbs/BackBreadcrumb';
import DetailsHeading from '../../components/detailsHeading/DetailsHeading';
import DriverDetailsTab from '../../tabs/Driver/Details';
import FilesView from '../../views/FilexView';
import LogsView from '../../views/LogsView';
import Loading from '../../components/loading/Loading';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import TripsView from '../../views/TripsView';
import { GET_USER } from '../../graphql/queries/GetUser';
import { ObjectType } from '../../enums/ObjetTypes';

export default function DriverDetails() {
	const [person, setPerson] = useState(null) as any;
	const { id } = useParams();
	const _id = id;
	const { loading, error, data, refetch } = useQuery(GET_USER, {
		variables: { _id }
	});
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		if (!loading && data) setPerson(data.getUser);
	}, [data]);
	if (loading || !person) return <Loading />;
	return (
		<Container maxW={'5xl'}>
			<BackBreadcrumb page="drivers" name="Kierowcy" />
			<DetailsHeading
				id={id as string}
				title={`${person.firstName} ${person.lastName}`}
				image={person.avatar}
				typeModel="user"
			/>
			<Tabs mt={'36px'}>
				<TabList>
					<Tab>Dane</Tab>
					<Tab>Pliki</Tab>
					<Tab>Wyjazdy</Tab>
					<Tab>Logi</Tab>
				</TabList>
				<TabPanels mt={'32px'}>
					<TabPanel>
						<DriverDetailsTab person={person} />
					</TabPanel>
					<TabPanel>
						{id ? <FilesView type={ObjectType.Driver} id={id} /> : ''}
					</TabPanel>
					<TabPanel>
						<TripsView variables={{ driverID: _id }} />
					</TabPanel>
					<TabPanel>
						<LogsView />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Container>
	);
}
