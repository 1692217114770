import { Box, Circle, Image, useToast } from '@chakra-ui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { LuPencil, LuPlus, LuTrash } from 'react-icons/lu';
import { useMutation, gql } from '@apollo/client';
import Resizer from 'react-image-file-resizer';

const SET_AVATAR = gql`
	mutation setAvatar($avatar: String!, $id: ID!, $typeModel: String!) {
		setAvatar(avatar: $avatar, id: $id, typeModel: $typeModel)
	}
`;

interface IProps {
	image?: string;
	alt: string;
	id: string;
	typeModel: string;
}

export default function Avatar({ image = '', alt, id, typeModel }: IProps) {
	const toast = useToast();
	const [binaryString, setBinaryString] = useState<string>('');
	const inputFileRef = useRef<HTMLInputElement>(null);
	const [file, setFile] = useState(null);
	const [setAvatar] = useMutation(SET_AVATAR);
	const setImage = () => {
		if (inputFileRef.current) inputFileRef.current.click();
	};
	const deleteImage = () => {
		uploadFile('');
	};
	const handleFileChange = (event: any) => {
		setFile(event.target.files[0]);
	};
	const uploadFile = async (binaryImage: string) => {
		try {
			await setAvatar({ variables: { avatar: binaryImage, id, typeModel } });
			setBinaryString(binaryImage);
			toast({
				title: `Zdjęcie zostało zmienione!`,
				status: 'success',
				isClosable: true,
				position: 'bottom-right'
			});
		} catch (error) {
			toast({
				title: `Nie udało się wysłać pliku!`,
				status: 'error',
				isClosable: true,
				position: 'bottom-right'
			});
		}
	};
	const parseToBinary = async () => {
		if (!file) {
			toast({
				title: `Brak pliku!`,
				status: 'warning',
				isClosable: true,
				position: 'bottom-right'
			});
			return;
		}
		Resizer.imageFileResizer(
			file,
			300,
			300,
			'WEBP',
			100,
			0,
			(base64: any) => uploadFile(base64),
			'base64'
		);
	};
	useEffect(() => {
		if (image) setBinaryString(image);
	}, [image]);
	useEffect(() => {
		if (file) parseToBinary();
	}, [file]);
	return (
		<Box position={'relative'}>
			<Image
				borderRadius="full"
				boxSize="125px"
				src={binaryString ? `${binaryString}` : ''}
				fallbackSrc="https://via.placeholder.com/150"
				alt={alt}
			/>
			<input
				type="file"
				ref={inputFileRef}
				onChange={handleFileChange}
				style={{ display: 'none' }}
			/>
			{binaryString === '' ? (
				<Circle
					position={'absolute'}
					top={0.1}
					left={0.1}
					bg={'green.500'}
					p={2}
					color={'white'}
					cursor={'pointer'}
					onClick={setImage}>
					<LuPlus />
				</Circle>
			) : (
				<Fragment>
					<Circle
						position={'absolute'}
						top={0.1}
						right={0.1}
						bg={'red.500'}
						p={2}
						color={'white'}
						cursor={'pointer'}
						onClick={deleteImage}>
						<LuTrash />
					</Circle>
					<Circle
						position={'absolute'}
						bottom={0.1}
						right={0.1}
						bg={'yellow.500'}
						p={2}
						color={'white'}
						cursor={'pointer'}
						onClick={setImage}>
						<LuPencil />
					</Circle>
				</Fragment>
			)}
		</Box>
	);
}
