import { DateRangeResult } from '../enums/Dates';
import { parseDate, parseStringToDate } from './parseDate';

export default function checkDateRange(
	dateFrom: Date,
	dateTo: Date
): DateRangeResult {
	const today: Date = new Date();
	const parsedDateFrom: string = parseDate(dateFrom);
	const parsedDateTo: string = parseDate(dateTo);
	const fromDate: Date | null = parseStringToDate(parsedDateFrom);
	const toDate: Date | null = parseStringToDate(parsedDateTo);

	if (!fromDate || !toDate) return DateRangeResult.Before;

	if (today < fromDate) return DateRangeResult.Before;
	else if (today >= fromDate && today <= toDate) return DateRangeResult.Between;
	else return DateRangeResult.After;
}
