import {
	Box,
	Card,
	CardBody,
	Flex,
	FormControl,
	FormLabel,
	Input,
	List,
	ListItem,
	ListIcon,
	Text,
	Button
} from '@chakra-ui/react';
import { LuAlertTriangle } from 'react-icons/lu';
import FormLayout from '../../layout/form';
import { Fragment } from 'react';

const SettingsPassword = () => {
	return (
		<FormLayout title="Zmień hasło" subTitle="Wypełnij wymagane pola.">
			<Fragment>
				<Flex flexDirection={'column'} gap={'1rem'}>
					<FormControl>
						<FormLabel>Obecne Hasło</FormLabel>
						<Input placeholder="Current Password" />
					</FormControl>
					<FormControl>
						<FormLabel>Nowe Hasło</FormLabel>
						<Input placeholder="New Password" />
					</FormControl>
					<FormControl>
						<FormLabel>Potiwerdź Nowe Hasło</FormLabel>
						<Input placeholder="Confirm New Password" />
					</FormControl>
				</Flex>
				<Box mt={8}>
					<Text fontSize={'lg'} fontWeight={'bold'}>
						Wymagania dotyczące hasła
					</Text>
					<Text fontSize={'sm'} fontWeight={400} color={'gray.500'}>
						Postępuj zgodnie z tym przewodnikiem, aby uzyskać silne hasło.
					</Text>
					<Flex
						flexFlow={{ base: 'column', lg: 'row' }}
						justifyContent={'space-between'}
						alignItems={{ base: 'start', lg: 'end' }}
						gap={3}>
						<List mt={3} spacing={1}>
							<ListItem style={{ display: 'flex', alignItems: 'center' }}>
								<ListIcon as={LuAlertTriangle} color={'gray.400'} />
								<Text fontSize={'xs'} fontWeight={400} color={'gray.500'}>
									Min 6 znaków
								</Text>
							</ListItem>
							<ListItem style={{ display: 'flex', alignItems: 'center' }}>
								<ListIcon as={LuAlertTriangle} color={'gray.400'} />
								<Text fontSize={'xs'} fontWeight={400} color={'gray.500'}>
									Min jeden znak specjalny
								</Text>
							</ListItem>
							<ListItem style={{ display: 'flex', alignItems: 'center' }}>
								<ListIcon as={LuAlertTriangle} color={'gray.400'} />
								<Text fontSize={'xs'} fontWeight={400} color={'gray.500'}>
									Min jedna cyfra
								</Text>
							</ListItem>
						</List>
						<Button colorScheme="blue" width={{ base: 'full', md: 'auto' }}>
							Zmień hasło
						</Button>
					</Flex>
				</Box>
			</Fragment>
		</FormLayout>
	);
};

export default SettingsPassword;
