import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardBody,
	Divider,
	Flex,
	Text,
	useToast
} from '@chakra-ui/react';
import TripPlanDay from './tripPlan/day';
import { useTripContext } from '../../../context/TripContext';
import TripPlan from './tripPlan/plan';
import { ITripPlan, TripDay } from '../../../types/ITrip';
import { useMutation, gql } from '@apollo/client';
import { ADD_TRIP, UPDATE_TRIP_DAYS } from '../../../graphql/mutations/Trip';
import { useNavigate } from 'react-router-dom';
import { parseDate, parseStringToDate } from '../../../utils/parseDate';
import { LuFile } from 'react-icons/lu';

//apiKey: 'AIzaSyAvHPyurcIOnpLmIUBF6w1UPtP3M_sbYeA',

const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!, $type: String!, $id: ID!) {
		uploadFile(file: $file, type: $type, id: $id)
	}
`;

const emptyDay: ITripPlan = {
	start: '',
	place: '',
	address: '',
	description: '',
	lat: 0,
	lng: 0
};

const TripDays: React.FC = () => {
	const inputFileRef = useRef<HTMLInputElement>(null);
	const [planFile, setPlanFile] = useState<any>(null);
	const toast = useToast();
	const [newTrip] = useMutation(ADD_TRIP);
	const [updateTripDays] = useMutation(UPDATE_TRIP_DAYS);
	const [uploadFile] = useMutation(UPLOAD_FILE);
	const { tripData } = useTripContext();
	const [days, setDays] = useState<TripDay[]>([]);
	const [activeDayIndex, setActiveDayIndex] = useState<any>(null);
	const navigate = useNavigate();
	useEffect(() => {
		if (tripData.dateFrom && tripData.dateTo && days.length === 0) {
			const copyTripData: any = { ...tripData };
			if (typeof copyTripData.dateFrom === 'string')
				copyTripData.dateFrom = parseStringToDate(
					parseDate(copyTripData.dateFrom)
				);
			if (typeof copyTripData.dateTo === 'string')
				copyTripData.dateTo = parseStringToDate(parseDate(copyTripData.dateTo));
			const dates = prepareSingleDay(
				copyTripData.dateFrom,
				copyTripData.dateTo
			);
			if (tripData._id && typeof tripData._id === 'string' && tripData.days)
				setDays([...copyTripData.days]);
			else setDays(dates);
			setActiveDayIndex(0);
		}
	}, [tripData]);

	const prepareSingleDay = (dateFrom: Date, dateTo: Date) => {
		const startDate: Date = new Date(dateFrom);
		const endDate: Date = new Date(dateTo);
		const dates: TripDay[] = [];
		for (
			let currentDate = startDate;
			currentDate <= endDate;
			currentDate.setDate(currentDate.getDate() + 1)
		) {
			const emptyDay: TripDay = addEmptyDay(currentDate);
			dates.push(emptyDay);
		}
		return dates;
	};

	const addEmptyDay = (currentDate: Date): TripDay => {
		const day = new Date(currentDate);
		return {
			day,
			distance: 0,
			hotel: '',
			notes: '',
			plan: [{ ...emptyDay }]
		};
	};

	const addItemToPlan = () => {
		const day = days[activeDayIndex].day;
		if (day !== null) {
			const dates: TripDay[] = [...days];
			const plan = dates[activeDayIndex].plan;
			plan.push(emptyDay);
			setDays(dates);
		}
	};

	const updateHotelAndNotes = (hotel: string, notes: string) => {
		const dates: TripDay[] = [...days];
		dates[activeDayIndex].hotel = hotel;
		dates[activeDayIndex].notes = notes;
		setDays(dates);
	};

	const updateDistance = (distance: number) => {
		const dates: TripDay[] = [...days];
		dates[activeDayIndex] = { ...dates[activeDayIndex], distance: distance };
		setDays(dates);
	};

	const updateDayPlan = (plan: ITripPlan[]) => {
		const dates: TripDay[] = [...days];
		dates[activeDayIndex].plan = [...plan];
		setDays(dates);
	};

	const countTotalDistance = () => {
		let total = 0;
		days.map((day: TripDay) => (total += day.distance));
		return Math.ceil(total / 1000);
	};

	const removeTypename = (obj: any): any => {
		if (Array.isArray(obj)) return obj.map((item) => removeTypename(item));
		else if (typeof obj === 'object' && obj !== null) {
			const { __typename, ...rest } = obj;
			for (const key in rest) rest[key] = removeTypename(rest[key]);
			return rest;
		} else return obj;
	};

	const saveTrip = async () => {
		const objectToSave = { ...tripData, ...{ days: days } };
		if (tripData._id && typeof tripData._id === 'string' && tripData.days) {
			const tripID: string = tripData._id;
			const copyDays = [...objectToSave.days];
			const days = copyDays.map((obj) => removeTypename(obj));
			try {
				await updateTripDays({
					variables: {
						tripID,
						days
					}
				});
				toast({
					title: 'Sukces!',
					description: 'Wyjazd został zaktualizowany!',
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'bottom-right'
				});
				navigate(`/trip/${tripID}`);
			} catch (error) {
				toast({
					title: 'Błąd!',
					description: 'Nie udało się zaktualizować wyjazdu.',
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'bottom-right'
				});
			}
		} else {
			try {
				const res = await newTrip({
					variables: objectToSave
				});
				if (planFile) {
					const file = planFile;
					const type = 'Trip';
					const id = res.data.newTrip._id;
					await uploadFile({ variables: { file, type, id } });
				}
				toast({
					title: 'Sukces!',
					description: 'Wyjazd został dodany!',
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'bottom-right'
				});
				navigate('/trips');
			} catch (error) {
				toast({
					title: 'Błąd!',
					description: 'Nie udało się dodać wyjazdu.',
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'bottom-right'
				});
			}
		}
	};
	const initUpload = () => {
		if (inputFileRef.current) inputFileRef.current.click();
	};

	const handleFileChange = (event: any) => {
		setPlanFile(event.target.files[0]);
	};
	return (
		<Flex flexFlow={'column'} gap={4}>
			<Flex flexFlow={{ base: 'column', lg: 'row' }} w={'100%'} gap={5} pb={20}>
				<Flex flexFlow={'column'} gap={4} flex={1}>
					<Flex flexFlow={'column'} gap={4} maxH={'65vh'} overflowY={'auto'}>
						{days.map((day: TripDay, i: number) => (
							<TripPlanDay
								key={i}
								numberOfDay={i}
								date={days[i].day}
								distance={day.distance}
								setActiveDayIndex={setActiveDayIndex}
								isActive={activeDayIndex === i}
							/>
						))}
					</Flex>
					<Divider />
					<Card>
						<CardBody>
							<Flex justifyContent={'space-between'}>
								<Text color={'gray.500'} fontWeight={600}>
									Łącznie
								</Text>
								<Text fontWeight={600} color={'gray.600'}>
									{countTotalDistance()} km
								</Text>
							</Flex>
						</CardBody>
					</Card>
					<Divider />
					<Button
						leftIcon={<LuFile />}
						colorScheme="blue"
						variant={'outline'}
						onClick={initUpload}>
						{planFile ? 'Zmień' : 'Dodaj'} plan wyjazdu
					</Button>
					<input
						type="file"
						ref={inputFileRef}
						onChange={handleFileChange}
						style={{ display: 'none' }}
					/>
					{planFile ? (
						<Button
							colorScheme="red"
							variant={'outline'}
							onClick={() => setPlanFile(null)}>
							Usuń plan wyjazdu
						</Button>
					) : (
						''
					)}
					<Button colorScheme="blue" mt={2} onClick={saveTrip}>
						Zapisz
					</Button>
				</Flex>
				<Box flex={3}>
					{activeDayIndex !== null ? (
						<TripPlan
							day={days[activeDayIndex]}
							plan={days[activeDayIndex].plan}
							startPlace={
								activeDayIndex > 0
									? days[activeDayIndex - 1].plan[
											days[activeDayIndex - 1].plan.length - 1
										]
									: null
							}
							updateDay={updateDayPlan}
							indexOfDay={activeDayIndex}
							addEmptyPoint={addItemToPlan}
							updateDistance={updateDistance}
							updateHotelAndNotes={updateHotelAndNotes}
						/>
					) : (
						''
					)}
				</Box>
			</Flex>
		</Flex>
	);
};

export default TripDays;
