import React, { Fragment, useEffect } from 'react';
import { ITrip } from '../../../../types/ITrip';
import { useTripContext } from '../../../../context/TripContext';
import TripDays from '../tripDays';

interface IProps {
	trip: ITrip;
}

const UpdateTripPlan: React.FC<IProps> = ({ trip }) => {
	const { tripData, setTripData } = useTripContext();
	useEffect(() => {
		setTripData({ ...tripData, ...trip });
	}, [trip]);
	return (
		<Fragment>
			<TripDays />
		</Fragment>
	);
};

export default UpdateTripPlan;
