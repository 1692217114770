import { gql } from '@apollo/client';

export const GET_AGENCY = gql`
	query agencyData($id: ID!) {
		agencyData(_id: $id) {
			id
			name
			nip
			address
			postalCode
			city
			phone
			email
			country
			person
			avatar
		}
	}
`;
