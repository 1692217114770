import { Button, Card, CardBody, Flex } from '@chakra-ui/react';

const FormActions = ({ cancel }: { cancel: any }) => {
	return (
		<Flex mt={6}>
			<Flex flex={1} display={{ base: 'none', md: 'flex' }}></Flex>
			<Card flex={3} w={'full'}>
				<CardBody>
					<Flex
						flexFlow={{ base: 'column', md: 'row' }}
						gap={4}
						justifyContent={'space-between'}>
						<Button colorScheme="gray" variant={'outline'} onClick={cancel}>
							ANULUJ
						</Button>
						<Button colorScheme="blue" type="submit">
							ZAPISZ
						</Button>
					</Flex>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default FormActions;
