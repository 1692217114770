import {
	Container,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel
} from '@chakra-ui/react';
import BackBreadcrumb from '../../components/breadcrumbs/BackBreadcrumb';
import DetailsHeading from '../../components/detailsHeading/DetailsHeading';
import FilesView from '../../views/FilexView';
import LogsView from '../../views/LogsView';
import { GET_AGENCY } from '../../graphql/queries/GetAgency';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import Loading from '../../components/loading/Loading';
import AgencyDetailsTab from '../../tabs/Agency/Details';
import TripsView from '../../views/TripsView';
import { ObjectType } from '../../enums/ObjetTypes';

export default function AgencyDetails() {
	const [agency, setAgency] = useState(null) as any;
	const { id } = useParams();
	const { loading, error, data } = useQuery(GET_AGENCY, {
		variables: { id }
	});
	useEffect(() => {
		if (!loading && data) setAgency(data.agencyData);
	}, [data]);
	if (loading || !agency) return <Loading />;
	return (
		<Container maxW={'5xl'}>
			<BackBreadcrumb page="agency" name="Biura" />
			<DetailsHeading
				id={id as string}
				title={agency.name}
				image={agency.avatar}
				typeModel="agency"
			/>
			<Tabs mt={'36px'}>
				<TabList>
					<Tab>Dane</Tab>
					<Tab>Pliki</Tab>
					<Tab>Wyjazdy</Tab>
					<Tab>Logi</Tab>
				</TabList>
				<TabPanels mt={'32px'}>
					<TabPanel>
						<AgencyDetailsTab agency={agency} />
					</TabPanel>
					<TabPanel>
						{id ? <FilesView type={ObjectType.Agency} id={id} /> : ''}
					</TabPanel>
					<TabPanel>
						<TripsView variables={{ agencyID: id }} />
					</TabPanel>
					<TabPanel>
						<LogsView />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Container>
	);
}
