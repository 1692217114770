import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_TRIP_LIST } from '../graphql/queries/GetTripList';
import Loading from '../components/loading/Loading';
import TableComponent from '../components/table';
import { useNavigate } from 'react-router-dom';
import { parseDate } from '../utils/parseDate';

interface IProps {
	variables?: any;
}

const columns = [
	{ name: 'Nazwa', key: 'name' },
	{ name: 'Od', key: 'dateFrom' },
	{ name: 'Do', key: 'dateTo' }
];

export default function TripsView({ variables }: IProps) {
	const history = useNavigate();
	const [formattedData, setFormattedData] = useState<any[]>([]);
	const { loading, data, refetch } = useQuery(GET_TRIP_LIST, {
		variables
	});
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		if (data) {
			const arr: any[] = [];
			data.tripList.forEach((el: any) => {
				const item = {
					id: el._id,
					name: el.name,
					car: el.car?.registrationNo,
					driver: `${el.driver?.firstName} ${el.driver?.lastName}`,
					dateFrom: parseDate(el.dateFrom),
					dateTo: parseDate(el.dateTo)
				};
				arr.push(item);
			});
			setFormattedData(arr);
		}
	}, [data]);
	const openDetails = (el: any) => {
		history(`/trip/${el.id}`);
	};
	if (loading) return <Loading />;
	return (
		<TableComponent
			header={'Wyjazdy'}
			columns={columns}
			data={formattedData}
			onOpen={openDetails}
		/>
	);
}
