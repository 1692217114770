import { Box, Card, CardBody, SimpleGrid } from '@chakra-ui/react';
import StackList from '../../components/stackList/StackList';
import { useEffect, useState } from 'react';
import { IUser } from '../../types/User';

interface IProps {
	person: IUser;
}

export default function DriverDetailsTab({ person }: IProps) {
	const [list, setList] = useState<any[]>([]);
	useEffect(() => {
		const { firstName, lastName, phone, email } = person;
		setList([
			{ key: 'Imię', value: firstName },
			{ key: 'Nazwisko', value: lastName },
			{ key: 'Telefon', value: phone },
			{ key: 'E-mail', value: email }
		]);
	}, [person]);
	return (
		<SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
			<Box>
				<Card>
					<CardBody>
						<StackList data={list} />
					</CardBody>
				</Card>
			</Box>
			<Box></Box>
		</SimpleGrid>
	);
}
