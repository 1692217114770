import {
	Card,
	CardBody,
	Flex,
	Text,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	SimpleGrid
} from '@chakra-ui/react';
import {
	LuHeart,
	LuMoreVertical,
	LuPencil,
	LuTrash,
	LuDownload
} from 'react-icons/lu';
import { FaFile } from 'react-icons/fa';
import { IFile } from '../../types/Files';

interface IProps {
	file: IFile;
	onDelete: (path: string) => void;
	download: (path: string) => void;
}

export default function FilesListItem({ file, onDelete, download }: IProps) {
	const deleteFile = () => {
		onDelete(file.path);
	};
	const downloadFile = () => {
		download(file.path);
	};
	return (
		<Card bg={'whiteAlpha.700'} _hover={{ background: 'white' }}>
			<CardBody>
				<SimpleGrid columns={{ base: 3 }}>
					<Flex alignItems={'center'} gap={5}>
						<FaFile fontSize={'3rem'} color={'orange'} />
						<Flex flexFlow={'column'}>
							{/* <Text fontSize={'sm'} fontWeight={'500'}>
								Skan
							</Text> */}
							<Text fontSize={'xs'}>{file.fileName}</Text>
						</Flex>
					</Flex>
					<Flex
						justifyContent={'space-between'}
						alignItems={'center'}
						color={'gray.600'}
						fontWeight={500}>
						<Text fontSize={'sm'}>{file.size}</Text>
						<Text fontSize={'sm'}>Utworzono {file.date}</Text>
					</Flex>
					<Flex justifyContent={'space-between'} alignItems={'center'}>
						<Flex flex={1} justifyContent={'center'}>
							<LuHeart />
						</Flex>
						<Menu>
							<MenuButton>
								<LuMoreVertical />
							</MenuButton>
							<MenuList minW={'150px'}>
								{/* <MenuItem
									fontSize={'md'}
									icon={<LuPencil fontWeight={'bold'} fontSize={'1rem'} />}>
									Zmień nazwę
								</MenuItem> */}
								<MenuItem
									fontSize={'sm'}
									icon={<LuDownload fontWeight={'bold'} fontSize={'1rem'} />}
									onClick={downloadFile}>
									Pobierz
								</MenuItem>
								<MenuItem
									fontSize={'sm'}
									color={'red'}
									icon={<LuTrash fontWeight={'bold'} fontSize={'1rem'} />}
									onClick={deleteFile}>
									Usuń
								</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
				</SimpleGrid>
			</CardBody>
		</Card>
	);
}
