// src/graphql/queries/GetPostsQuery.ts
import { gql } from '@apollo/client';

export const GET_CAR_LIST = gql`
	query {
		carsList {
			id
			brand
			carModel
			registrationNo
		}
	}
`;
