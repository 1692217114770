import {
	Button,
	Card,
	CardBody,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	SimpleGrid,
	useToast
} from '@chakra-ui/react';
import { LuSearch, LuLayoutGrid, LuLayoutList, LuPlus } from 'react-icons/lu';
import { Fragment, useEffect, useRef, useState } from 'react';
import FilesGridItem from '../components/files/GridItem';
import FilesListItem from '../components/files/ListItem';
import { useMutation, useQuery, gql } from '@apollo/client';
import { ObjectType } from '../enums/ObjetTypes';
import calculateFileSize from '../utils/calculateFileSize';
import { IFile, IResFile } from '../types/Files';

const FILE_PATH =
	'https://logic-transport-local.s3.eu-central-1.amazonaws.com/';

const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!, $type: String!, $id: ID!) {
		uploadFile(file: $file, type: $type, id: $id)
	}
`;

const DELETE_FILE = gql`
	mutation deleteFile($pathToFile: String!) {
		deleteFile(pathToFile: $pathToFile)
	}
`;

const DOWNLOAD_FILE = gql`
	mutation downloadFile($path: String!) {
		downloadFile(path: $path)
	}
`;

const GET_FILES = gql`
	query filesList($type: String!, $id: ID!) {
		filesList(type: $type, id: $id) {
			LastModified
			Key
			Size
		}
	}
`;

const colsGrid = { base: 1, sm: 2, lg: 3 };
const colsList = { base: 1 };

interface IProps {
	type: ObjectType;
	id: String;
}

export default function FilesView({ type, id }: IProps) {
	const toast = useToast();
	const [files, setFiles] = useState<IFile[]>([]);
	const [file, setFile] = useState(null);
	const [view, setView] = useState<string>('grid');
	const [cols, setCols] = useState<any>(colsGrid);
	const [uploadFile] = useMutation(UPLOAD_FILE);
	const [deleteFile] = useMutation(DELETE_FILE);
	const [downloadFile] = useMutation(DOWNLOAD_FILE);
	const { loading, error, data, refetch } = useQuery(GET_FILES, {
		variables: { type, id }
	});
	const inputFileRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (file) handleUpload();
	}, [file]);

	useEffect(() => {
		if (data?.filesList) {
			const filesList = data.filesList;
			let parsedList: IFile[] = [];
			filesList.map((el: IResFile) => {
				const currentDate = new Date(+el.LastModified);
				const day = currentDate.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if necessary
				const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Get month (months are zero-indexed) and pad with leading zero if necessary
				const year = currentDate.getFullYear(); // Get full year
				const formattedDate = `${day}.${month}.${year}`;
				parsedList.push({
					fileName: el.Key.substring(el.Key.lastIndexOf('/') + 1),
					path: el.Key,
					date: formattedDate,
					size: calculateFileSize(el.Size)
				});
			});
			setFiles(parsedList);
		}
	}, [data]);

	const toggle = () => {
		const v: string = view === 'grid' ? 'list' : 'grid';
		const c = v === 'grid' ? colsGrid : colsList;
		setCols(c);
		setView(v);
	};

	const initUpload = () => {
		if (inputFileRef.current) inputFileRef.current.click();
	};

	const handleFileChange = (event: any) => {
		setFile(event.target.files[0]);
	};

	const handleUpload = async () => {
		try {
			if (!file) {
				toast({
					title: `Brak pliku!`,
					status: 'warning',
					isClosable: true,
					position: 'bottom-right'
				});
				return;
			}
			await uploadFile({ variables: { file, type, id } });
			toast({
				title: `Plik został dodany!`,
				status: 'success',
				isClosable: true,
				position: 'bottom-right'
			});
			setFile(null);
			refetch();
		} catch (error) {
			toast({
				title: `Nie udało się wysłać pliku!`,
				status: 'error',
				isClosable: true,
				position: 'bottom-right'
			});
			setFile(null);
		}
	};

	const onDeleteFile = async (pathToFile: string) => {
		try {
			await deleteFile({ variables: { pathToFile } });
			toast({
				title: `Plik został usunięty!`,
				status: 'success',
				isClosable: true,
				position: 'bottom-right'
			});
			refetch();
		} catch (error) {
			toast({
				title: `Nie udało się usunąć pliku!`,
				status: 'error',
				isClosable: true,
				position: 'bottom-right'
			});
		}
	};

	const onDownloadFile = async (path: string) => {
		try {
			const link = document.createElement('a');
			link.href = `${FILE_PATH}${path}`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.log('error', error);
		}
	};

	return (
		<Flex flexFlow={'column'} gap={4}>
			<Flex justifyContent={'end'}>
				<Button leftIcon={<LuPlus />} colorScheme="blue" onClick={initUpload}>
					Dodaj plik
				</Button>
				<input
					type="file"
					ref={inputFileRef}
					onChange={handleFileChange}
					style={{ display: 'none' }}
				/>
			</Flex>
			<Card>
				<CardBody>
					<Flex gap={3}>
						<InputGroup size={'lg'}>
							<InputLeftElement pointerEvents="none">
								<LuSearch color={'gray.300'} />
							</InputLeftElement>
							<Input type="tel" placeholder="Szukaj" />
						</InputGroup>
						<Flex
							p={'.1rem'}
							alignItems={'center'}
							border={'1px solid'}
							borderColor={'gray.200'}
							borderRadius={5}>
							<Button
								onClick={toggle}
								bg={view === 'grid' ? 'gray.200' : 'white'}>
								<LuLayoutGrid fontSize={'1.25rem'} />
							</Button>
							<Button
								onClick={toggle}
								bg={view === 'list' ? 'gray.200' : 'white'}>
								<LuLayoutList fontSize={'1.25rem'} />
							</Button>
						</Flex>
					</Flex>
				</CardBody>
			</Card>
			<SimpleGrid columns={{ ...cols }} gap={5} mt={'32px'}>
				{files?.length > 0
					? files.map((file: IFile, i: number) => (
							<Fragment key={i}>
								{view === 'grid' ? (
									<FilesGridItem
										file={file}
										onDelete={onDeleteFile}
										download={onDownloadFile}
									/>
								) : (
									<FilesListItem
										file={file}
										onDelete={onDeleteFile}
										download={onDownloadFile}
									/>
								)}
							</Fragment>
						))
					: 'Brak danych'}
			</SimpleGrid>
		</Flex>
	);
}
