import {
	Badge,
	Flex,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Tooltip,
	PopoverFooter,
	ButtonGroup,
	Button,
	useDisclosure,
	useToast
} from '@chakra-ui/react';
import { StatusesEnum, StatusesNamesEnum } from '../../enums/Statuses';
import { StatusColors } from '../../enums/StatusColors';
import { Fragment } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_TRIP_STATUS } from '../../graphql/mutations/Trip';

interface IStatusProps {
	tripID?: string;
	status: StatusesEnum;
	typeBadge?: string;
	onConfirm?: () => void;
}

export default function TableStatus({
	tripID,
	status,
	typeBadge = 'circle',
	onConfirm
}: IStatusProps) {
	const [updateTripStatus] = useMutation(UPDATE_TRIP_STATUS);
	const { onOpen, onClose, isOpen } = useDisclosure();
	const toast = useToast();
	let badge, label;
	const sizeBadge = typeBadge === 'circle' ? '1rem' : '';
	switch (status) {
		case StatusesEnum.Draft:
			label = StatusesNamesEnum.Draft;
			badge = (
				<Badge
					borderRadius={sizeBadge}
					w={sizeBadge}
					h={sizeBadge}
					variant="solid"
					colorScheme={StatusColors.Draft}>
					{typeBadge === 'circle' ? '' : label}
				</Badge>
			);
			break;

		case StatusesEnum.Confirmed:
			label = StatusesNamesEnum.Confirmed;
			badge = (
				<Badge
					borderRadius={sizeBadge}
					w={sizeBadge}
					h={sizeBadge}
					variant="solid"
					colorScheme={StatusColors.Confirmed}>
					{typeBadge === 'circle' ? '' : label}
				</Badge>
			);
			break;

		case StatusesEnum.Active:
			label = StatusesNamesEnum.Active;
			badge = (
				<Badge
					borderRadius={sizeBadge}
					w={sizeBadge}
					h={sizeBadge}
					variant="solid"
					colorScheme={StatusColors.Active}>
					{typeBadge === 'circle' ? '' : label}
				</Badge>
			);
			break;

		case StatusesEnum.Done:
			label = StatusesNamesEnum.Done;
			badge = (
				<Badge
					borderRadius={sizeBadge}
					w={sizeBadge}
					h={sizeBadge}
					variant="solid"
					colorScheme={StatusColors.Done}>
					{typeBadge === 'circle' ? '' : label}
				</Badge>
			);
			break;

		default:
			label = '';
			badge = (
				<Badge
					borderRadius={sizeBadge}
					w={sizeBadge}
					h={sizeBadge}
					variant="solid">
					{typeBadge === 'circle' ? '' : label}
				</Badge>
			);
			break;
	}
	const confirmTrip = async () => {
		if (!tripID) return;
		try {
			const confirmed: string = 'Confirmed';
			await updateTripStatus({
				variables: {
					tripID,
					status: confirmed
				}
			});
			if (onConfirm) onConfirm();
			onClose();
			toast({
				title: 'Sukces!',
				description: 'Wyjazd został potwierdzony!',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'bottom-right'
			});
		} catch (error) {
			toast({
				title: 'Błąd!',
				description: 'Nie udało się potwierdzić wyjazdu.',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'bottom-right'
			});
		}
	};
	if (typeBadge !== 'circle') return <Fragment>{badge}</Fragment>;
	else if (typeBadge === 'circle' && status === StatusesEnum.Draft && tripID)
		return (
			<Flex justify={'center'} alignItems={'center'}>
				<Tooltip label={label} placement="top">
					<Flex>
						<Popover
							isOpen={isOpen}
							onOpen={onOpen}
							onClose={onClose}
							placement="bottom"
							closeOnBlur={true}>
							<PopoverTrigger>{badge}</PopoverTrigger>
							<PopoverContent>
								<PopoverHeader fontWeight="semibold">
									Potwierdzenie
								</PopoverHeader>
								<PopoverBody>
									Czy napewno chcesz potwierdzić wyjazd?
								</PopoverBody>
								<PopoverFooter display="flex" justifyContent="flex-end">
									<ButtonGroup size="sm">
										<Button variant="outline" onClick={onClose}>
											Nie
										</Button>
										<Button colorScheme="green" onClick={confirmTrip}>
											Tak
										</Button>
									</ButtonGroup>
								</PopoverFooter>
							</PopoverContent>
						</Popover>
					</Flex>
				</Tooltip>
			</Flex>
		);
	return (
		<Flex justify={'center'} alignItems={'center'}>
			<Tooltip label={label} placement="top">
				{badge}
			</Tooltip>
		</Flex>
	);
}
