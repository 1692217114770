import { Box, Card, CardBody, SimpleGrid } from '@chakra-ui/react';
import StackList from '../../components/stackList/StackList';
import { useEffect, useState } from 'react';
import { IAgency } from '../../types/Agency';

interface IProps {
	agency: IAgency;
}

export default function AgencyDetailsTab({ agency }: IProps) {
	const [list, setList] = useState<any[]>([]);
	useEffect(() => {
		const {
			name,
			nip,
			address,
			postalCode,
			city,
			country,
			email,
			phone,
			person
		} = agency;
		setList([
			{ key: 'Nazwa', value: name },
			{ key: 'NIP', value: nip },
			{ key: 'Adres', value: address },
			{ key: 'Kod pocztowy', value: postalCode },
			{ key: 'Miejscowość', value: city },
			{ key: 'Kraj', value: country },
			{ key: 'E-mail', value: email },
			{ key: 'Telefon', value: phone },
			{ key: 'Przedstawiciel', value: person }
		]);
	}, [agency]);
	return (
		<SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
			<Box>
				<Card>
					<CardBody>
						<StackList data={list} />
					</CardBody>
				</Card>
			</Box>
			<Box></Box>
		</SimpleGrid>
	);
}
