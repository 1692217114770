import {
	Button,
	Container,
	Skeleton,
	useDisclosure,
	useToast
} from '@chakra-ui/react';
import TableComponent from '../../components/table';
import { useMutation, useQuery } from '@apollo/client';
import { GET_DRIVERS_LIST } from '../../graphql/queries/GetDriversList';
import { useEffect, useState } from 'react';
import AlertWindow from '../../components/alert';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/pageHeader';
import { LuPlus } from 'react-icons/lu';
import Loading from '../../components/loading/Loading';
import { DELETE_USER } from '../../graphql/mutations/DeleteUser';

const columns = [
	{ name: 'Osoba', key: 'Name' },
	{ name: 'Telefon', key: 'phone' },
	{ name: 'Email', key: 'email' }
	// { name: 'Wyjazdy', key: 'trips' },
	// { name: 'Kolejny wyjazd', key: 'next' },
	// { name: 'kraj', key: 'city' }
];

const role = 'driver';

const DriversList = () => {
	const [itemToDelete, setItemToDelete] = useState<any>();
	const [subTitle, setSubTitle] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { loading, error, data, refetch } = useQuery(GET_DRIVERS_LIST, {
		variables: { role }
	});
	const [deleteUser] = useMutation(DELETE_USER);
	const history = useNavigate();
	const toast = useToast();
	const openEdit = (el: any) => {
		history(`/drivers/edit/${el._id}`);
	};
	const deleteItem = (el: any) => {
		setSubTitle(`Czy napewno chcesz usunąć ${el.Name}?`);
		setItemToDelete(el);
		onOpen();
	};
	const onDelete = async () => {
		const id = itemToDelete._id;
		await deleteUser({
			variables: {
				id
			}
		});
		onClose();
		refetch();
		toast({
			title: 'Sukces!',
			description: 'Pojazd został usunięty.',
			status: 'success',
			duration: 3000,
			isClosable: true,
			position: 'bottom-right'
		});
	};
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		setItemToDelete(null);
	}, [onClose]);
	const openDetails = (el: any) => {
		history(`/drivers/${el._id}`);
	};
	const createNew = (el: any) => {
		history(`/drivers/new`);
	};
	return (
		<Container maxW={'5xl'}>
			<PageHeader
				title="Kierowcy"
				actions={
					<Button leftIcon={<LuPlus />} colorScheme="blue" onClick={createNew}>
						Dodaj
					</Button>
				}
			/>
			<Skeleton isLoaded={!loading} height="80px">
				{loading ? (
					<Loading />
				) : (
					<TableComponent
						header={'Kierowcy'}
						columns={columns}
						data={data.getUsersByRole}
						onEdit={openEdit}
						onDelete={deleteItem}
						onOpen={openDetails}
					/>
				)}
			</Skeleton>
			<AlertWindow
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				title={'Usuń kierowcę'}
				subTitle={subTitle}
				onDelete={onDelete}
			/>
		</Container>
	);
};

export default DriversList;
