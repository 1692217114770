import { Container, useToast } from '@chakra-ui/react';
import FormLayout from '../../layout/form';
import CarForm from '../../components/forms/vehicle';
import FormActions from '../../components/forms/actions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number } from 'yup';
import { IVehicle } from '../../types/Vehicle';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_CAR } from '../../graphql/mutations/AddCar';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_CAR } from '../../graphql/queries/GetCar';
import { useEffect } from 'react';
import { UPDATE_CAR } from '../../graphql/mutations/UpdateCar';

const defaultValues: IVehicle = {
	registrationNo: '',
	brand: '',
	carModel: '',
	vin: '',
	seats: null,
	yearOfProduction: null,
	engine: ''
};

const schema = object({
	registrationNo: string().required(),
	brand: string().required(),
	carModel: string().required(),
	vin: string().nullable(),
	seats: number().nullable(),
	yearOfProduction: number().nullable(),
	engine: string().nullable()
});

const VehicleEdit = () => {
	const { id } = useParams();
	const { loading, error, data } = useQuery(GET_CAR, {
		variables: { id }
	});
	const [updateCar] = useMutation(UPDATE_CAR);
	const toast = useToast();
	const navigate = useNavigate();
	const { register, handleSubmit, reset } = useForm<IVehicle | any>({
		defaultValues,
		resolver: yupResolver(schema),
		mode: 'onTouched' // default is "onSubmit"
	});
	useEffect(() => {
		if (!loading && data) {
			if (!data.getCar) navigate('/fleet');
			reset(data.getCar);
		}
	}, [data]);
	const onSubmitHandler = async (values: IVehicle) => {
		await updateCar({
			variables: {
				id,
				...values
			}
		});
		toast({
			title: 'Sukces!',
			description: 'Dane pojazdu zostały zmienone.',
			status: 'success',
			duration: 3000,
			isClosable: true,
			position: 'bottom-right'
		});
		navigate('/fleet');
	};
	const cancel = () => {
		navigate('/fleet');
	};
	return (
		<Container maxW={'5xl'}>
			<form onSubmit={handleSubmit(onSubmitHandler)}>
				<FormLayout title="Dane pojazdu" subTitle="Wypełnij wymagane pola.">
					<CarForm register={register} />
				</FormLayout>
				<FormActions cancel={cancel} />
			</form>
		</Container>
	);
};

export default VehicleEdit;
