import { Container, useToast } from '@chakra-ui/react';
import FormLayout from '../../layout/form';
import AgencyForm from '../../components/forms/agency';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { IAgency } from '../../types/Agency';
import FormActions from '../../components/forms/actions';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { GET_AGENCY } from '../../graphql/queries/GetAgency';
import { UPDATE_AGENCY } from '../../graphql/mutations/UpdateAgency';

const defaultValues: IAgency = {
	name: '',
	nip: null,
	address: null,
	postalCode: null,
	city: null,
	country: null,
	phone: null,
	email: null,
	person: null
};

const schema = object({
	name: string().required(),
	nip: string().nullable(),
	address: string().nullable(),
	postalCode: string().nullable(),
	city: string().nullable(),
	country: string().nullable(),
	phone: string().nullable(),
	email: string().email().nullable(),
	person: string().nullable()
});

const AgencyEdit = () => {
	const { id } = useParams();
	const { loading, error, data } = useQuery(GET_AGENCY, {
		variables: { id }
	});
	const [updateAgency] = useMutation(UPDATE_AGENCY);
	const navigate = useNavigate();
	const toast = useToast();
	const { register, handleSubmit, reset } = useForm<IAgency | any>({
		defaultValues,
		resolver: yupResolver(schema),
		mode: 'onTouched' // default is "onSubmit"
	});
	const onSubmit = async (values: IAgency) => {
		await updateAgency({
			variables: {
				id,
				...values
			}
		});
		toast({
			title: 'Sukces!',
			description: 'Dane zostały zakutalizowane.',
			status: 'success',
			duration: 3000,
			isClosable: true,
			position: 'bottom-right'
		});
		navigate('/agency');
	};
	useEffect(() => {
		if (!loading && data) {
			if (!data.agencyData) navigate('/agency');
			reset(data.agencyData);
		}
	}, [data]);
	const cancel = () => {
		navigate('/agency');
	};
	return (
		<Container maxW={'5xl'}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormLayout
					title="Edytuj biuro podrózy"
					subTitle="Wypełnij pola aby edytować biuro">
					<AgencyForm register={register} />
				</FormLayout>
				<FormActions cancel={cancel} />
			</form>
		</Container>
	);
};

export default AgencyEdit;
