import { Flex } from '@chakra-ui/react';

interface IProps {
	icon: any;
	click: any;
}

export default function ButtonHover({ icon, click }: IProps) {
	return (
		<Flex
			w={'35px'}
			h={'35px'}
			justifyContent={'center'}
			alignItems={'center'}
			cursor={'pointer'}
			borderRadius={'35px'}
			_hover={{
				backgroundColor: 'gray.100',
				width: '35px',
				height: '35px'
			}}
			onClick={click}
			zIndex={2}>
			{icon}
		</Flex>
	);
}
