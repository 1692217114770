import { Box, Button, Container, Skeleton, useToast } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_TRIP_LIST } from '../../graphql/queries/GetTripList';
import TableComponent from '../../components/table';
import Loading from '../../components/loading/Loading';
import PageHeader from '../../components/pageHeader';
import { LuPlus } from 'react-icons/lu';
import TableStatus from '../../components/trip/tableStatus';
import TableRequired from '../../components/trip/tableRequired';
import { parseDate } from '../../utils/parseDate';
import TripDriverList from '../../components/trip/driverList';
import TripVehicleList from '../../components/trip/vehicleList';
import {
	UPDATE_TRIP_DRIVER,
	UPDATE_TRIP_VEHICLE
} from '../../graphql/mutations/UpdateTrip_DriverVehicle';
import { StatusesEnum } from '../../enums/Statuses';
import getTripStatus from '../../utils/getTripStatus';
import { ITripData } from '../../types/ITrip';

const columns = [
	{ name: '', key: 'status', customField: true },
	{ name: 'Nazwa', key: 'name' },
	{ name: 'Pojazd', key: 'car', customField: true },
	{ name: 'Kierowca', key: 'driver', customField: true },
	{ name: 'Biuro', key: 'agency' },
	{ name: 'Od', key: 'dateFrom' },
	{ name: 'Do', key: 'dateTo' }
];

interface RefObject {
	getList: () => any;
}

const TripList = () => {
	const toast = useToast();
	const refDrivers = useRef<RefObject>(null);
	const refVehicles = useRef<RefObject>(null);
	const [selectedDriver, setSelectedDriver] = useState<{ id: string } | null>(
		null
	);
	const [selectedVehicle, setSelectedVehicle] = useState<{ id: string } | null>(
		null
	);
	const [formattedData, setFormattedData] = useState<any[]>([]);
	const history = useNavigate();
	const { loading, data, refetch } = useQuery(GET_TRIP_LIST);
	const [updateTripDriver] = useMutation(UPDATE_TRIP_DRIVER);
	const [updateTripVehicle] = useMutation(UPDATE_TRIP_VEHICLE);
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		if (data) {
			const arr: any[] = [];
			data.tripList.forEach((el: ITripData) => {
				const dateFrom: string = parseDate(el.dateFrom);
				const dateTo: string = parseDate(el.dateTo);
				const status: StatusesEnum = getTripStatus(el);
				const item = {
					id: el._id,
					name: el.name,
					car: (
						<TableRequired
							item={el}
							value={el.car ? `${el.car.registrationNo}` : null}
							title={'Pojazd'}
							onClear={clearSelected}
							onSave={saveVehicle}
							element={
								<TripVehicleList
									ref={refVehicles}
									dateFrom={formatDate(dateFrom)}
									dateTo={formatDate(dateTo)}
									onSelect={onSetVehicle}
									activeItem={selectedVehicle}
								/>
							}
						/>
					),
					driver: (
						<TableRequired
							item={el}
							value={
								el.driver
									? `${el.driver.firstName + ' ' + el.driver.lastName}`
									: null
							}
							title={'Kierowca'}
							onClear={clearSelected}
							onSave={saveDriver}
							element={
								<TripDriverList
									ref={refDrivers}
									dateFrom={formatDate(dateFrom)}
									dateTo={formatDate(dateTo)}
									onSelect={onSetDriver}
									activeItem={selectedDriver}
								/>
							}
						/>
					),
					agency: el.agency.name,
					dateFrom: dateFrom,
					dateTo: dateTo,
					status: (
						<TableStatus tripID={el._id} status={status} onConfirm={refetch} />
					)
				};
				arr.push(item);
			});
			setFormattedData(arr);
		}
	}, [data]);

	const formatDate = (inputDate: string): string => {
		const parts = inputDate.split('.');
		const formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
		return formattedDate;
	};
	const clearSelected = () => {
		setSelectedDriver(null);
		setSelectedVehicle(null);
	};
	const onSetDriver = (id: string | null) => {
		const val = id ? { id } : null;
		setSelectedDriver(val);
	};
	const saveDriver = async (el: any) => {
		if (refDrivers.current) {
			const driverEL = refDrivers.current.getList();
			const driver = driverEL.id;
			const tripID = el._id;
			await updateTripDriver({
				variables: {
					tripID,
					driver
				}
			});
			refetch();
			toast({
				title: 'Sukces!',
				description: 'Wyjazd został zaktualizowany!',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'bottom-right'
			});
		}
	};
	const onSetVehicle = (id: string | null): void => {
		const val = id ? { id } : null;
		setSelectedVehicle(val);
	};
	const saveVehicle = async (el: any) => {
		if (refVehicles.current) {
			const vehicle = refVehicles.current.getList();
			const car = vehicle.id;
			const tripID = el._id;
			await updateTripVehicle({
				variables: {
					tripID,
					car
				}
			});
			refetch();
			toast({
				title: 'Sukces!',
				description: 'Wyjazd został zaktualizowany!',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'bottom-right'
			});
		}
	};
	const createNew = () => {
		history(`/trip/new`);
	};
	const openDetails = (el: any) => {
		history(`/trip/${el.id}`);
	};
	return (
		<Container maxW={'6xl'}>
			<PageHeader
				title="Wyjazdy"
				actions={
					<Button leftIcon={<LuPlus />} colorScheme="blue" onClick={createNew}>
						Dodaj
					</Button>
				}
			/>
			<Skeleton isLoaded={!loading} height="80px">
				{loading ? (
					<Loading />
				) : (
					<Box pb={'24px'}>
						<TableComponent
							header={'Wyjazdy'}
							columns={columns}
							data={formattedData}
							onOpen={openDetails}
						/>
					</Box>
				)}
			</Skeleton>
		</Container>
	);
};

export default TripList;
