import { Skeleton, Text } from '@chakra-ui/react';
import SelectList from '../selectList';
import { useQuery } from '@apollo/client';
import { GET_TRIP_CARS } from '../../graphql/queries/GetTripCars';
import {
	Ref,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState
} from 'react';
import { ICar } from '../../types/ICar';
import { ISelectListItem } from '../../types/SelectList';

interface IProps {
	dateFrom: string;
	dateTo: string;
	onSelect: any;
	activeItem: any;
}

interface RefObject {
	getList: () => any;
}

const TripVehicleList = forwardRef(
	({ dateFrom, dateTo, onSelect, activeItem }: IProps, ref: Ref<RefObject>) => {
		useImperativeHandle(ref, () => ({ getList }));
		const [selected, setSelected] = useState<string>('');
		const [list, setList] = useState([] as ISelectListItem[]);
		const { loading, data } = useQuery(GET_TRIP_CARS, {
			variables: {
				dateFrom,
				dateTo
			}
		});
		useEffect(() => {
			setSelected(activeItem);
		}, [activeItem]);
		useEffect(() => {
			if (data?.tripCars) {
				const arr: ISelectListItem[] = [];
				data.tripCars.map((car: ICar) => {
					arr.push({
						id: car._id,
						title: `${car.brand} ${car.carModel}`,
						subTitle: car.registrationNo,
						available: car.isAvailable,
						note: car.note,
						isActive: false,
						setActive: setActive
					});
				});
				const sortedArr = arr.sort(
					(a: any, b: any) => b.available - a.available
				);
				setList(sortedArr);
			}
		}, [data]);
		const getList = () => {
			return list.find((e: any) => e.id === selected);
		};
		const setActive = (id: string) => {
			setSelected(id);
			onSelect(id);
		};
		if (loading) return <Skeleton></Skeleton>;
		if (!loading && list.length === 0) return <Text>Brak danych</Text>;
		return (
			<SelectList
				title="Wybierz pojazd"
				values={list}
				active={selected}
				setActive={setActive}
			/>
		);
	}
);

export default TripVehicleList;
