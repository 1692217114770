const parseDate = (date: string | Date): string => {
	let dateFromDate =
		typeof date === 'string' ? new Date(Number(date)) : new Date(date);
	let monthDate = Number(dateFromDate.getMonth() + 1);
	let month = monthDate < 10 ? '0' + monthDate : monthDate;
	return (
		String(dateFromDate.getDate()).padStart(2, '0') +
		'.' +
		month +
		'.' +
		dateFromDate.getFullYear()
	);
};

function parseStringToDate(dateString: string): Date | null {
	const parts = dateString.split('.');
	if (parts.length === 3) {
		const formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
		return new Date(formattedDate);
	}
	return null; // Return null if the format is invalid
}

export { parseStringToDate, parseDate };
