// Define enum for date comparison result
export enum DateRangeResult {
	Before,
	Between,
	After
}

// Define enum for days of the week
export enum DaysOfWeek {
	Sunday,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday
}
