import React from 'react';
import { Card, CardBody, Flex, Text } from '@chakra-ui/react';

const FormLayout = ({
	children,
	title,
	subTitle
}: {
	children: React.ReactNode | null | undefined;
	title: string;
	subTitle?: string;
}) => {
	return (
		<Flex flexFlow={{ base: 'column', md: 'row' }} mb={4}>
			<Flex flexFlow={'column'} flex={1} pb={4}>
				<Text fontSize={'lg'} fontWeight={600}>
					{title}
				</Text>
				<Text fontSize={'sm'} color={'gray.600'} mt={1}>
					{subTitle}
				</Text>
			</Flex>
			<Flex flex={3}>
				<Card width={'full'}>
					<CardBody>{children}</CardBody>
				</Card>
			</Flex>
		</Flex>
	);
};

export default FormLayout;
