import { Flex, Text } from '@chakra-ui/react';
import { Fragment, ReactNode } from 'react';

interface IProps {
	title: string;
	step: number;
	children: ReactNode;
}

export default function TripStep({ title, step, children }: IProps) {
	return (
		<Fragment>
			<Flex>
				<Flex gap={3} alignItems={'center'}>
					<Flex
						p={1}
						border={'1px solid'}
						borderColor={'blue.900'}
						borderRadius={20}
						justifyContent={'center'}
						alignItems={'center'}
						width={'40px'}
						height={'40px'}>
						{step}
					</Flex>
					<Flex flexFlow={'column'}>
						<Text fontWeight={600}>{title}</Text>
					</Flex>
				</Flex>
			</Flex>
			<Flex
				py={'32px'}
				px={'20px'}
				ml={'20px'}
				borderLeft={'1px solid'}
				borderColor={'gray.200'}
				w={'full'}>
				{children}
			</Flex>
		</Fragment>
	);
}
