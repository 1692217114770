export enum StatusColors {
	Done = 'gray',
	Draft = 'orange',
	Confirmed = 'teal',
	Active = 'blue'
}

export enum StatusColorsRGB {
	Done = '#718096',
	Draft = '#DD6B20',
	Confirmed = '#319795',
	Active = '#3182ce'
}
