import { Container, useToast } from '@chakra-ui/react';
import FormLayout from '../../layout/form';
import AgencyForm from '../../components/forms/agency';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { IAgency } from '../../types/Agency';
import FormActions from '../../components/forms/actions';
import { ADD_AGENCY } from '../../graphql/mutations/AddAgency';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const defaultValues: IAgency = {
	name: '',
	nip: null,
	address: null,
	postalCode: null,
	city: null,
	country: null,
	phone: null,
	email: null,
	person: null
};

const schema = object({
	name: string().required(),
	nip: string().nullable(),
	address: string().nullable(),
	postalCode: string().nullable(),
	city: string().nullable(),
	country: string().nullable(),
	phone: string().nullable(),
	email: string().email().nullable(),
	person: string().nullable()
});

const AgencyNew = () => {
	const [createAgency] = useMutation(ADD_AGENCY);
	const navigate = useNavigate();
	const toast = useToast();
	const { register, handleSubmit } = useForm<IAgency | any>({
		defaultValues,
		resolver: yupResolver(schema),
		mode: 'onTouched' // default is "onSubmit"
	});
	const onSubmit = async (values: IAgency) => {
		await createAgency({
			variables: values
		});
		toast({
			title: 'Success!',
			description: 'Driver added to list.',
			status: 'success',
			duration: 3000,
			isClosable: true,
			position: 'bottom-right'
		});
		navigate('/agency');
	};
	const cancel = () => {
		navigate('/agency');
	};
	return (
		<Container maxW={'5xl'}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormLayout
					title="Nowe biuro podrózy"
					subTitle="Wypełnij wymagane pola">
					<AgencyForm register={register} />
				</FormLayout>
				<FormActions cancel={cancel} />
			</form>
		</Container>
	);
};

export default AgencyNew;
