import { useAuth } from '../../hooks/useAuth';
import PersonForm from '../forms/person';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { IUser } from '../../types/User';
import { useEffect } from 'react';
import FormLayout from '../../layout/form';

const defaultValues: IUser = {
	firstName: '',
	lastName: '',
	phone: '',
	email: ''
};

const schema = object({
	firstName: string().required(),
	lastName: string().required(),
	phone: string().required(),
	email: string().email().nullable()
});

const SettingsBasicInfo = () => {
	const { firstName, lastName, email, phone } = useAuth();
	const { register, handleSubmit, setValue } = useForm<IUser | any>({
		defaultValues,
		resolver: yupResolver(schema),
		mode: 'onTouched' // default is "onSubmit"
	});
	useEffect(() => {
		setValue('firstName', firstName);
		setValue('lastName', lastName);
		setValue('email', email);
		setValue('phone', phone);
	}, []);
	const onSubmitHandler = async (values: IUser) => {
		console.log(values);
	};
	return (
		<FormLayout title="Dane podstawowe" subTitle="Wypełnij wymagane pola.">
			<form onSubmit={handleSubmit(onSubmitHandler)}>
				<PersonForm register={register} />
			</form>
		</FormLayout>
	);
};

export default SettingsBasicInfo;
