import { gql } from '@apollo/client';

export const UPDATE_TRIP_DRIVER = gql`
	mutation updateTripDriver($tripID: String!, $driver: String!) {
		updateTripDriver(tripID: $tripID, driver: $driver)
	}
`;

export const UPDATE_TRIP_VEHICLE = gql`
	mutation updateTripVehicle($tripID: String!, $car: String!) {
		updateTripVehicle(tripID: $tripID, car: $car)
	}
`;
