import { Flex, Switch, FormControl, FormLabel } from '@chakra-ui/react';
import FormLayout from '../../layout/form';

const SettingsNotifications = () => {
	return (
		<FormLayout title="Powiadomienia" subTitle="">
			<Flex flexFlow={'column'} gap={'1rem'}>
				<FormControl
					display="flex"
					alignItems="center"
					justifyContent={'space-between'}>
					<FormLabel htmlFor="email-alerts" mb="0">
						E-mail
					</FormLabel>
					<Switch id="email-alerts" />
				</FormControl>
				<FormControl
					display="flex"
					alignItems="center"
					justifyContent={'space-between'}>
					<FormLabel htmlFor="email-alerts" mb="0">
						SMS
					</FormLabel>
					<Switch id="newsletter" />
				</FormControl>
			</Flex>
		</FormLayout>
	);
};

export default SettingsNotifications;
