import { Container, useToast } from '@chakra-ui/react';
import FormLayout from '../../layout/form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import FormActions from '../../components/forms/actions';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { IUser } from '../../types/User';
import FormDriver from '../../components/forms/driver';
import { UPDATE_USER } from '../../graphql/mutations/UpdateUser';
import { GET_USER } from '../../graphql/queries/GetUser';

const defaultValues: IUser = {
	firstName: '',
	lastName: '',
	phone: '',
	email: ''
};

const schema = object({
	firstName: string().required(),
	lastName: string().required(),
	phone: string().nullable(),
	email: string().email().nullable()
});

const DriverEdit = () => {
	const { id } = useParams();
	const _id = id;
	const { loading, error, data, refetch } = useQuery(GET_USER, {
		variables: { _id }
	});
	const [updateUser] = useMutation(UPDATE_USER);
	const navigate = useNavigate();
	const toast = useToast();
	const { register, handleSubmit, reset } = useForm<IUser | any>({
		defaultValues,
		resolver: yupResolver(schema),
		mode: 'onTouched' // default is "onSubmit"
	});
	const onSubmit = async (values: IUser) => {
		await updateUser({
			variables: {
				id,
				...values
			}
		});
		toast({
			title: 'Sukces!',
			description: 'Dane kierowcy zostały zmienione.',
			status: 'success',
			duration: 3000,
			isClosable: true,
			position: 'bottom-right'
		});
		navigate('/drivers');
	};
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		if (!loading && data) {
			if (!data.getUser) navigate('/drivers');
			reset(data.getUser);
		}
	}, [data]);
	const cancel = () => {
		navigate('/drivers');
	};
	return (
		<Container maxW={'5xl'}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormLayout title="Kierowca" subTitle="Wprowadź dane kierowcy">
					<FormDriver register={register} />
				</FormLayout>
				<FormActions cancel={cancel} />
			</form>
		</Container>
	);
};

export default DriverEdit;
