import { Flex, Text } from '@chakra-ui/react';

import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';
import ButtonHover from '../controls/ButtonHover';

interface IProps {
	numberOfItems: any;
	currentPage: any;
	prevPage: any;
	nextPage: any;
}

export default function TablePagination({
	numberOfItems,
	currentPage,
	prevPage,
	nextPage
}: IProps) {
	return (
		<Flex
			justifyContent={'space-between'}
			w={'full'}
			alignItems={'center'}
			color={'gray.600'}>
			<Flex
				flexFlow={'row'}
				ml={2}
				fontSize={'sm'}
				alignItems={'center'}
				gap={1}>
				<Text>Razem</Text>
				<Text fontWeight={'600'} color={'gray.600'}>
					{numberOfItems}
				</Text>
			</Flex>
			<Flex gap={3} alignItems={'center'}>
				<Flex alignItems={'center'} gap={1}>
					<Text fontSize={'sm'}>Strona</Text>
					<Text fontSize={'sm'} fontWeight={'600'}>
						{currentPage} z {Math.ceil(numberOfItems / 10)}
					</Text>
				</Flex>
				<Flex gap={1}>
					<ButtonHover icon={<LuChevronLeft />} click={prevPage} />
					<ButtonHover icon={<LuChevronRight />} click={nextPage} />
				</Flex>
			</Flex>
		</Flex>
	);
}
