import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_CAR } from '../../graphql/queries/GetCar';
import { useEffect, useState } from 'react';
import {
	Container,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel
} from '@chakra-ui/react';
import BackBreadcrumb from '../../components/breadcrumbs/BackBreadcrumb';
import VehicleDetailsTab from '../../tabs/Vehicle/Details';
import VehicleLogsTag from '../../tabs/Vehicle/Logs';
import VehicleGalleryTab from '../../tabs/Vehicle/Gallery';
import DetailsHeading from '../../components/detailsHeading/DetailsHeading';
import TripsView from '../../views/TripsView';
import { ObjectType } from '../../enums/ObjetTypes';
import FilesView from '../../views/FilexView';

const VehicleDetails = () => {
	const [car, setCar] = useState(null) as any;
	const params = useParams();
	const id = params.id;
	const { loading, error, data, refetch } = useQuery(GET_CAR, {
		variables: { id }
	});
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		if (!loading && data) setCar(data.getCar);
	}, [data]);
	if (loading || !car) return <>Loading</>;
	return (
		<Container maxW={'5xl'}>
			<BackBreadcrumb page="fleet" name="Flota" />
			<DetailsHeading
				id={id as string}
				title={car.registrationNo || ''}
				image={car.avatar}
				typeModel="vehicle"
			/>
			<Tabs mt={'36px'}>
				<TabList>
					<Tab>Dane</Tab>
					<Tab>Wyjazdy</Tab>
					<Tab>Pliki</Tab>
					<Tab>Galeria</Tab>
					{/* <Tab>Web</Tab> */}
					<Tab>Logi</Tab>
				</TabList>
				<TabPanels mt={'32px'}>
					<TabPanel>
						<VehicleDetailsTab car={car} />
					</TabPanel>
					<TabPanel>
						<TripsView variables={{ carID: id }} />
					</TabPanel>
					<TabPanel>
						{id ? <FilesView type={ObjectType.Agency} id={id} /> : ''}
					</TabPanel>
					<TabPanel>
						<VehicleGalleryTab />
					</TabPanel>
					{/* <TabPanel>Web</TabPanel> */}
					<TabPanel>
						<VehicleLogsTag />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Container>
	);
};

export default VehicleDetails;
