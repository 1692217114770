import { gql } from '@apollo/client';

export const ADD_TRIP = gql`
	mutation newTrip(
		$status: String!
		$name: String!
		$dateFrom: String!
		$dateTo: String!
		$agency: ID!
		$car: String
		$driver: String
		$people: Int
		$notes: String
		$comments: [String]
		$days: [InputDay]
	) {
		newTrip(
			status: $status
			name: $name
			dateFrom: $dateFrom
			dateTo: $dateTo
			agency: $agency
			car: $car
			driver: $driver
			people: $people
			notes: $notes
			comments: $comments
			days: $days
		) {
			_id
		}
	}
`;

export const UPDATE_TRIP_DAYS = gql`
	mutation updateTripDays($tripID: String!, $days: [InputDay]) {
		updateTripDays(tripID: $tripID, days: $days)
	}
`;

export const UPDATE_TRIP_STATUS = gql`
	mutation updateTripStatus($tripID: String!, $status: String!) {
		updateTripStatus(tripID: $tripID, status: $status)
	}
`;
