import { Flex, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { LuSearch } from 'react-icons/lu';

interface IProps {
	setSearchTerm: any;
}

export default function TableSearch({ setSearchTerm }: IProps) {
	return (
		<Flex justifyContent={'space-between'} alignItems={'center'}>
			<InputGroup
				border={0}
				borderWidth={0}
				outline={0}
				borderColor={'transparent'}
				my={2}
				size={'md'}
				_hover={{
					borderColor: 'transparent',
					outline: 0,
					borderWidth: 0
				}}
				_active={{
					borderColor: 'transparent',
					outline: 0,
					borderWidth: 0
				}}
				_focus={{
					borderColor: 'transparent',
					outline: 0,
					borderWidth: 0
				}}>
				<InputLeftElement pointerEvents="none">
					<LuSearch color="gray.300" />
				</InputLeftElement>
				<Input
					onChange={(e: any) => setSearchTerm(e.target.value)}
					type="text"
					placeholder="Szukaj"
					border={0}
					borderWidth={0}
					outline={0}
					borderColor={'transparent'}
					_hover={{
						borderColor: 'transparent',
						outline: 0,
						borderWidth: 0,
						boxShadow: 'none'
					}}
					_active={{
						borderColor: 'transparent',
						outline: 0,
						borderWidth: 0,
						boxShadow: 'none'
					}}
					_focus={{
						borderColor: 'transparent',
						outline: 'none',
						borderWidth: 0,
						boxShadow: 'none'
					}}
				/>
			</InputGroup>
		</Flex>
	);
}
