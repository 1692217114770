import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
	mutation login($code: String!, $username: String!, $password: String!) {
		login(input: { code: $code, username: $username, password: $password }) {
			_id
			userName
			token
		}
	}
`;
