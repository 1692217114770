// src/graphql/queries/GetPostsQuery.ts
import { gql } from '@apollo/client';

export const GET_DRIVERS_LIST = gql`
	query getUsersByRole($role: String!) {
		getUsersByRole(role: $role) {
			_id
			Name
			phone
			email
		}
	}
`;
