import { Box, Card, CardBody, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { LuBus, LuUsers, LuMapPin, LuBuilding2 } from 'react-icons/lu';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_TRIP_LIST } from '../graphql/queries/GetTripList';
import TimelineItem from '../components/dashboard/TimelineItem';
import ItemIcon from '../components/dashboard/ItemIcon';
import { GET_DASHBOARD } from '../graphql/queries/GetDashboard';
import { GoogleMap } from '@react-google-maps/api';

const Dashboard: React.FC = (props: any) => {
	const [events, setEvents] = useState([]);
	const [tripList, { data }] = useLazyQuery(GET_TRIP_LIST);
	const {
		loading: loadingDashboard,
		error: errorQuery2,
		data: dataDashboard
	} = useQuery(GET_DASHBOARD);
	// const { isLoaded } = useJsApiLoader({
	// 	id: 'google-map-script',
	// 	googleMapsApiKey: 'AIzaSyAvHPyurcIOnpLmIUBF6w1UPtP3M_sbYeA'
	// });
	// useEffect(() => {
	// 	if (!data) return;
	// 	const formattedFoEvents = data.tripList.map((trip: any) => {
	// 		return {
	// 			title: `${trip.name} (${trip.car?.registrationNo || ''})`,
	// 			start: formatDate(+trip.dateFrom),
	// 			end: formatDate(+trip.dateTo)
	// 		};
	// 	});
	// 	setEvents(formattedFoEvents);
	// }, [data]);
	const handleViewDidMount = (view: any) => {
		const dateFrom = view.startStr;
		const dateTo = view.endStr;
		tripList({ variables: { dateFrom, dateTo } });
	};
	const formatDate = (timestamp: number) => {
		const date = new Date(timestamp);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};
	return (
		<Flex flexFlow={'column'} gap={6} px={10}>
			<SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap={4}>
				<ItemIcon
					name="Busy"
					value={dataDashboard?.getDashboard?.busCount || 0}
					icon={<LuBus />}
				/>
				<ItemIcon
					name="Kierowcy"
					value={dataDashboard?.getDashboard?.driverCount || 0}
					icon={<LuUsers />}
				/>
				<ItemIcon
					name="Wyjazdy (30dni)"
					value={data?.tripList?.length || 0}
					icon={<LuMapPin />}
				/>
				<ItemIcon
					name="Biura"
					value={dataDashboard?.getDashboard?.agencyCount || 0}
					icon={<LuBuilding2 />}
				/>
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 2 }} gap={4}>
				<Card>
					<CardBody>
						<Flex flexFlow={'column'} gap={8}>
							<Box>
								<Text fontSize={'xl'} fontWeight={700}>
									Wyjazdy
								</Text>
								<Text color={'gray.500'} fontWeight={400}>
									Osatnie i najblizsze wyjazdy z tego miesiaca
								</Text>
							</Box>
							{/* {data?.tripList?.map((trip: any) => (
								<TimelineItem key={trip._id} trip={trip} />
							))} */}
						</Flex>
					</CardBody>
				</Card>
				<Card>
					<CardBody>
						<FullCalendar
							plugins={[dayGridPlugin]}
							initialView="dayGridMonth"
							locale="pl"
							headerToolbar={false}
							datesSet={handleViewDidMount}
							events={events}
						/>
					</CardBody>
				</Card>
			</SimpleGrid>
			<Card>
				{/* {isLoaded ? ( */}
				<GoogleMap
					zoom={5}
					center={{
						lat: 52.52,
						lng: 13.405
					}}
					mapContainerStyle={{
						width: '100%',
						height: '100%',
						paddingBottom: '35rem'
					}}>
					<></>
				</GoogleMap>
				{/* ) : (
					''
				)} */}
			</Card>
		</Flex>
	);
};

export default Dashboard;
