import { Card, CardBody, Flex, Text } from '@chakra-ui/react';

const ItemIcon = ({
	name,
	value,
	icon
}: {
	name: string;
	value: string | number;
	icon: any;
}) => {
	return (
		<Card>
			<CardBody>
				<Flex justifyContent={'space-between'} alignItems={'center'}>
					<Flex
						flexFlow={'column'}
						justifyContent={'start'}
						alignItems={'start'}>
						<Text fontSize={'sm'} color={'gray.400'}>
							{name}
						</Text>
						<Text fontSize={'xl'} fontWeight={600}>
							{value}
						</Text>
					</Flex>
					<Flex
						background={'blue.500'}
						borderRadius={'md'}
						color={'white'}
						fontSize={'2xl'}
						width={'48px'}
						height={'48px'}
						justifyContent={'center'}
						alignItems={'center'}>
						{icon}
					</Flex>
				</Flex>
			</CardBody>
		</Card>
	);
};

export default ItemIcon;
