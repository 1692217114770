import { Fragment, useEffect, useState } from 'react';
import { GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api';
import {
	AspectRatio,
	Button,
	Card,
	CardBody,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Text,
	Textarea
} from '@chakra-ui/react';
import { LuPlus } from 'react-icons/lu';
import TripPlanItem from './planItem';
import { ITripPlan } from '../../../../types/ITrip';

interface IProps {
	day: any;
	plan: any;
	startPlace: any;
	updateDay: any;
	indexOfDay: number;
	addEmptyPoint: any;
	updateDistance: any;
	updateHotelAndNotes: any;
}

export default function TripPlan({
	day,
	plan,
	startPlace,
	updateDay,
	indexOfDay,
	addEmptyPoint,
	updateDistance,
	updateHotelAndNotes
}: IProps) {
	const [directions, setDirections] = useState<any[]>([]);
	const [destinations, setDestinations] = useState<any[]>([]);
	const [hotel, setHotel] = useState<string>('');
	const [notes, setNotes] = useState<string>('');

	useEffect(() => {
		getDirections();
	}, [destinations]);

	useEffect(() => {
		const newDestinations: any[] = [];
		if (startPlace) {
			const { lat, lng } = startPlace;
			if (lat !== 0 && lng !== 0)
				newDestinations.push({
					lat,
					lng
				});
		}
		plan.map((item: ITripPlan) => {
			const { lat, lng } = item;
			if (lat !== 0 && lng !== 0)
				newDestinations.push({
					lat,
					lng
				});
		});
		setDestinations(newDestinations);
		setHotel(day.hotel);
		setNotes(day.notes);
	}, [indexOfDay]);

	const getDirections = () => {
		const directionsService = new google.maps.DirectionsService();
		const origin = destinations[0];
		const destination = destinations[destinations.length - 1];
		let distance = 0; // Initialize distance

		const processRoute = (request: any) => {
			return new Promise((resolve) => {
				directionsService.route(request, (result: any, status: any) => {
					if (status === google.maps.DirectionsStatus.OK) {
						// Update total distance
						distance += result.routes[0].legs[0].distance.value;
						resolve(result);
					} else {
						console.error(`Error fetching directions ${result}`);
						resolve(null);
					}
				});
			});
		};
		const requests = [];
		for (let i = 0; i < destinations.length - 1; i++) {
			const request = {
				origin: i === 0 ? origin : destinations[i],
				destination:
					i === destinations.length - 2 ? destination : destinations[i + 1],
				travelMode: google.maps.TravelMode.DRIVING
			};
			requests.push(request);
		}
		Promise.all(requests.map((req) => processRoute(req))).then((directions) => {
			updateDistance(distance);
			setDirections(directions.filter((direction) => direction !== null));
		});
	};
	const addPoint = () => {
		const currentPlan = { ...plan[plan.length - 1] };
		if (currentPlan.lat === 0 && currentPlan.lng === 0)
			alert('Ostatni punkt jest pusty');
		else addEmptyPoint();
	};
	const updatePlanItem = (item: ITripPlan, index: number) => {
		plan[index] = item;
		updateDay(plan);
	};
	const handleChangeHotel = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value;
		setHotel(val);
		updateHotelAndNotes(val, notes);
	};
	const handleChangeNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const val = e.target.value;
		setNotes(val);
		updateHotelAndNotes(hotel, val);
	};
	return (
		<Fragment>
			<Flex flexFlow={'column'} gap={3}>
				<Heading color={'gray.600'}>Informacje</Heading>
				<Card>
					<CardBody>
						<Flex flexFlow={'column'} gap={5}>
							<FormControl>
								<FormLabel>Hotel</FormLabel>
								<Input value={hotel} onChange={handleChangeHotel} />
							</FormControl>
							<FormControl>
								<FormLabel>Uwagi</FormLabel>
								<Textarea value={notes} onChange={handleChangeNotes} />
							</FormControl>
						</Flex>
					</CardBody>
				</Card>
				<Heading mt={5} color={'gray.600'}>
					Trasa
				</Heading>
				{startPlace ? (
					<Card bgColor={'gray.100'}>
						<CardBody>
							<Flex flexFlow={'column'}>
								<FormLabel>Miejsce wyjazdu</FormLabel>
								<Text>
									{startPlace.place}, {startPlace.address}
								</Text>
								{/* <Text
									fontSize={'sm'}
									color={'gray.500'}
									fontStyle={'italic'}
									mt={2}>
									*miejsce zakończenia porzedniego dnia
								</Text> */}
							</Flex>
						</CardBody>
					</Card>
				) : (
					''
				)}
				{plan.map((item: ITripPlan, index: number) => (
					<TripPlanItem
						key={index}
						item={item}
						destinations={destinations}
						setDestinations={setDestinations}
						planItemIndex={index}
						updatePlanItem={updatePlanItem}
						indexOfDay={indexOfDay}
					/>
				))}
				<Button leftIcon={<LuPlus />} onClick={addPoint}>
					Dodaj kolejny punkt
				</Button>
			</Flex>
			<Heading mt={5} color={'gray.600'} mb={3}>
				Mapa
			</Heading>
			{destinations.length > 0 ? (
				<AspectRatio ratio={16 / 9}>
					<GoogleMap zoom={1} center={destinations[0]}>
						{destinations.map((destination, index) => (
							<Marker key={index} position={destination} />
						))}
						{directions.map((direction, index) => (
							<DirectionsRenderer key={index} directions={direction} />
						))}
					</GoogleMap>
				</AspectRatio>
			) : (
				<Text color={'gray.600'} mt={3}>
					Brak punktów na trasie
				</Text>
			)}
		</Fragment>
	);
}
