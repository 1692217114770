import {
	Avatar,
	AvatarBadge,
	IconButton,
	Card,
	CardBody,
	Flex,
	Text
} from '@chakra-ui/react';
import { useAuth } from '../../hooks/useAuth';

const SettingsAvatar = () => {
	const { firstName, lastName, roleName } = useAuth();
	return (
		<Card mb={3}>
			<CardBody>
				<Flex gap={'1.75rem'}>
					<Avatar
						size="xl"
						name={`${firstName} ${lastName}`}
						src="https://bit.ly/tioluwani-kolawole">
						{/* <AvatarBadge
							as={IconButton}
							size="sm"
							rounded="full"
							top="-10px"
							colorScheme="red"
							aria-label="remove Image"
						/> */}
					</Avatar>
					<Flex justifyContent={'center'} flexFlow={'column'}>
						<Text fontSize={'2xl'} fontWeight={'bold'}>
							{firstName} {lastName}
						</Text>
						<Text fontSize={'md'} color={'gray.500'}>
							{roleName}
						</Text>
					</Flex>
				</Flex>
			</CardBody>
		</Card>
	);
};

export default SettingsAvatar;
