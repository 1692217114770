import {
	Card,
	CardBody,
	Flex,
	Image,
	SimpleGrid,
	Text
} from '@chakra-ui/react';
import { LuTrash } from 'react-icons/lu';

export default function VehicleGalleryTab() {
	return (
		<SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} gap={5}>
			<Flex flexFlow={'column'} gap={2}>
				<Card>
					<CardBody p={0} position={'relative'}>
						<Image
							src="https://images.unsplash.com/photo-1544620347-c4fd4a3d5957?q=80&w=4240&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
							borderRadius={10}
						/>
						<Flex
							position={'absolute'}
							top={'.5rem'}
							right={'.5rem'}
							p={1}
							borderRadius={20}
							bg={'transparent'}
							color={'red.500'}
							border={'1px solid'}
							borderColor={'red.500'}
							cursor={'pointer'}>
							<LuTrash />
						</Flex>
					</CardBody>
				</Card>
				<Text>file.jpg</Text>
			</Flex>
		</SimpleGrid>
	);
}
