import { Box, Flex, Card, CardBody, Text } from '@chakra-ui/react';
import { ISelectListItem } from '../../types/SelectList';

const SelectListItem = ({
	id,
	title,
	subTitle,
	available,
	note,
	isActive,
	setActive
}: ISelectListItem) => {
	return (
		<Card
			onClick={() => setActive(id)}
			border={'2px solid'}
			borderColor={isActive ? 'blue.600' : 'transparent'}
			cursor={available ? 'pointer' : 'not-allowed'}
			_hover={{ borderColor: 'blue.500' }}>
			<CardBody>
				<Flex p={2} justifyContent={'space-between'}>
					<Flex flexFlow={'column'}>
						<Text fontWeight={'600'}>{title}</Text>
						<Text fontSize={'xs'} color={'gray.500'}>
							{subTitle}
						</Text>
					</Flex>
					{available ? (
						<Flex alignItems={'center'}>
							<Text color={'green.600'} fontWeight={'600'}>
								DOSTĘPNY
							</Text>
						</Flex>
					) : (
						<Box>
							<Text color={'red.600'} fontWeight={'600'}>
								NIEDOSTĘPNY
							</Text>
							<Text color={'red.600'} fontSize={'xs'}>
								(do {note})
							</Text>
						</Box>
					)}
				</Flex>
			</CardBody>
		</Card>
	);
};

export default SelectListItem;
