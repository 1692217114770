import { ITableHead } from '../../types/Table';
import { Th, Thead, Tr } from '@chakra-ui/react';

const TableHead = ({ columns, displayActions }: ITableHead) => {
	return (
		<Thead bgColor={'gray.100'}>
			<Tr>
				{columns.map((col, index) => (
					<Th key={index}>{col.name}</Th>
				))}
				{displayActions ? <Th></Th> : ''}
			</Tr>
		</Thead>
	);
};

export default TableHead;
