import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import AppRoutes from './routes';
import './assets/style.css';
import 'react-dropzone-uploader/dist/styles.css';

import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';
import '@fontsource/plus-jakarta-sans/200.css';
import '@fontsource/plus-jakarta-sans/300.css';
import '@fontsource/plus-jakarta-sans/400.css';
import '@fontsource/plus-jakarta-sans/500.css';
import '@fontsource/plus-jakarta-sans/600.css';
import '@fontsource/plus-jakarta-sans/700.css';
import '@fontsource/plus-jakarta-sans/800.css';

const theme = extendTheme({
	fonts: {
		heading: `'Plus Jakarta Sans', sans-serif`,
		body: `'Inter', sans-serif`
	}
});

export const App = () => (
	<ChakraProvider theme={theme}>
		<AppRoutes />
	</ChakraProvider>
);
