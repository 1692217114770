import { Container, Divider, Flex } from '@chakra-ui/react';
import SettingsAvatar from '../components/settings/Avatar';
import SettingsBasicInfo from '../components/settings/BasicInfo';
import SettingsPassword from '../components/settings/Password';
import SettingsNotifications from '../components/settings/Notifications';
import SettingsDisable from '../components/settings/Disable';

const Settings = () => {
	return (
		<Container maxW={'5xl'}>
			<Flex flexDirection={'column'} gap={'1rem'}>
				<SettingsAvatar />
				<SettingsBasicInfo />
				<Divider my={2} />
				<SettingsPassword />
				<Divider my={2} />
				<SettingsNotifications />
				{/* <Divider my={2} />
				<SettingsDisable /> */}
			</Flex>
		</Container>
	);
};

export default Settings;
