import { gql } from '@apollo/client';

export const GET_AGENCY_LIST = gql`
    query agencyList {
        agencyList{
            id
            name
        }
    }
`;

