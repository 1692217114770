// src/graphql/queries/GetPostsQuery.ts
import { gql } from '@apollo/client';

export const GET_TRIP_CARS = gql`
	query tripCars($dateFrom: String!, $dateTo: String!) {
		tripCars(dateFrom: $dateFrom, dateTo: $dateTo) {
			_id
			brand
			carModel
			registrationNo
			seats
			isAvailable
			note
		}
	}
`;
