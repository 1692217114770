// AppRoutes.tsx
import React from 'react';
import {
	createBrowserRouter,
	Navigate,
	RouterProvider
} from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import AppLayout from '../layout';
import FleetList from '../pages/Fleet/List';
import FleetNew from '../pages/Fleet/New';
import DriversNew from '../pages/Drivers/New';
import DriversList from '../pages/Drivers/List';
import AuthLayout from '../layout/authLayout';
import VehicleDetails from '../pages/Fleet/Details';
import TripNew from '../pages/Trips/New';
import Agency from '../pages/Agency/List';
import AgencyNew from '../pages/Agency/New';
import TripList from '../pages/Trips/List';
import TripCalendar from '../pages/Trips/Calendar';
import Settings from '../pages/Settings';
import AgencyEdit from '../pages/Agency/Edit';
import DriverEdit from '../pages/Drivers/Edit';
import TripDetails from '../pages/Trips/Details';
import VehicleEdit from '../pages/Fleet/Edit';
import DriverDetails from '../pages/Drivers/Details';
import AgencyDetails from '../pages/Agency/Details';
import TripUpdate from '../pages/Trips/Edit';

const AppRoutes: React.FC = () => {
	const routes = createBrowserRouter([
		{
			element: <AuthLayout />,
			children: [
				{ path: '/login', element: <Login /> },
				{
					element: <AppLayout />,
					children: [
						{
							path: '/',
							element: <Dashboard />
						},
						{
							path: '/trip/new',
							element: <TripNew />
						},
						{
							path: '/trip/update/:id',
							element: <TripUpdate />
						},
						{
							path: '/trip/:id',
							element: <TripDetails />
						},
						{
							path: '/trips',
							element: <TripList />
						},
						{
							path: '/trips/calendar',
							element: <TripCalendar />
						},
						{
							path: '/fleet',
							element: <FleetList />
						},
						{
							path: '/fleet/new',
							element: <FleetNew />
						},
						{
							path: '/fleet/edit/:id',
							element: <VehicleEdit />
						},
						{
							path: '/fleet/:id',
							element: <VehicleDetails />
						},
						{
							path: '/drivers',
							element: <DriversList />
						},
						{
							path: '/drivers/new',
							element: <DriversNew />
						},
						{
							path: '/drivers/edit/:id',
							element: <DriverEdit />
						},
						{
							path: '/drivers/:id',
							element: <DriverDetails />
						},
						{
							path: '/agency',
							element: <Agency />
						},
						{
							path: '/agency/new',
							element: <AgencyNew />
						},
						{
							path: '/agency/edit/:id',
							element: <AgencyEdit />
						},
						{
							path: '/agency/:id',
							element: <AgencyDetails />
						},
						{
							path: '/settings',
							element: <Settings />
						},
						{
							path: '*',
							element: <Navigate to="/login" />
						}
					]
				}
			]
		}
	]);

	return <RouterProvider router={routes} />;
};

export default AppRoutes;
