import {
	Box,
	Container,
	Divider,
	useToast,
	Flex,
	IconButton
} from '@chakra-ui/react';
import FormLayout from '../../layout/form';
import CarForm from '../../components/forms/vehicle';
import FormActions from '../../components/forms/actions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number } from 'yup';
import { IVehicle } from '../../types/Vehicle';
import { useMutation } from '@apollo/client';
import { ADD_CAR } from '../../graphql/mutations/AddCar';
import { useNavigate } from 'react-router-dom';
import { LuPlus } from 'react-icons/lu';
import { useEffect, useState } from 'react';
import DropzoneForm from '../../components/dropzoneForm';

const defaultValues: IVehicle = {
	registrationNo: '',
	brand: '',
	carModel: '',
	vin: '',
	seats: null,
	yearOfProduction: null,
	engine: ''
};

const schema = object().shape({
	registrationNo: string().required(),
	brand: string().required(),
	carModel: string().required(),
	vin: string().nullable(),
	seats: number().nullable(),
	yearOfProduction: number().nullable(),
	engine: string().nullable()
});

const VehicleNew = () => {
	const [files, setFiles] = useState([{ name: '', file: null }]);
	const [addCar, { data, loading }] = useMutation(ADD_CAR);
	const toast = useToast();
	const navigate = useNavigate();
	const { register, handleSubmit } = useForm<IVehicle | any>({
		defaultValues,
		resolver: yupResolver(schema),
		mode: 'onTouched' // default is "onSubmit"
	});
	const onSubmitHandler = async (values: IVehicle) => {
		await addCar({
			variables: values
		});
		toast({
			title: 'Success!',
			description: 'Vehicle added to list.',
			status: 'success',
			duration: 3000,
			isClosable: true,
			position: 'bottom-right'
		});
	};

	useEffect(() => {
		if (!loading && data) {
			// const id = data.addCar.id;
			// const checkFiles = files.filter((f) => f.file !== null);
			navigate('/fleet');
		}
	}, [data, loading]);

	const addFile = () => {
		const f = [...files];
		f.push({
			name: '',
			file: null
		});
		setFiles(f);
	};

	const setFileName = (value: string, i: number) => {
		const f = [...files];
		f[i].name = value;
		setFiles(f);
	};

	const canDeleteFile = (): boolean => {
		return files.length > 1;
	};

	const deleteFile = (i: number) => {
		if (!canDeleteFile()) return;
		const f = [...files];
		f.splice(i, 1);
		setFiles(f);
	};
	const setFile = (i: number, file: any) => {
		const f = [...files];
		f[i].file = file;
		setFiles(f);
	};
	const cancel = () => {
		navigate('/fleet');
	};
	return (
		<Container maxW={'5xl'}>
			<form onSubmit={handleSubmit(onSubmitHandler)}>
				<FormLayout title="Dane pojazdu" subTitle="Wypełnij wymagane pola.">
					<CarForm register={register} />
				</FormLayout>
				{/* <Divider my={6} />
				{files.map((file: any, index: number) => (
					<DropzoneForm
						key={index}
						file={file}
						index={index}
						canDelete={canDeleteFile}
						setFileName={setFileName}
						deleteFile={deleteFile}
						setFile={setFile}
					/>
				))}
				<Flex pt={2} pb={5}>
					<Box display={{ base: 'none', md: 'block' }} flex={1}></Box>
					<IconButton
						onClick={addFile}
						flex={3}
						w={'full'}
						variant="outline"
						colorScheme="green"
						aria-label="Dodaj"
						icon={<LuPlus />}
					/>
				</Flex> */}
				<FormActions cancel={cancel} />
			</form>
		</Container>
	);
};

export default VehicleNew;
