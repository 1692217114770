import { Box, FormControl, FormLabel, Flex } from '@chakra-ui/react';
import SelectListItem from './item';
import { ISelectListItem } from '../../types/SelectList';

interface IProps {
	title: string;
	subTitle?: string;
	values: any[];
	active: string;
	setActive: any;
}

const SelectList = ({ title, values, active, setActive }: IProps) => {
	return (
		<Box>
			<FormControl>
				<FormLabel fontWeight={500}>{title}</FormLabel>
			</FormControl>
			<Flex flexFlow={'column'} gap={3}>
				{values.map((v: ISelectListItem, i: number) => (
					<SelectListItem
						key={i}
						id={v.id}
						title={v.title}
						subTitle={v.subTitle}
						available={v.available}
						note={v.note}
						isActive={active === v.id}
						setActive={setActive}
					/>
				))}
			</Flex>
		</Box>
	);
};

export default SelectList;
