import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Container } from '@chakra-ui/react';
import { useRef, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_TRIP_LIST } from '../../graphql/queries/GetTripList';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { StatusesEnum } from '../../enums/Statuses';
import getTripStatus from '../../utils/getTripStatus';
import { ITripData } from '../../types/ITrip';
import { getTripStatusColor } from '../../utils/getTripStatusColor';
import { StatusColorsRGB } from '../../enums/StatusColors';

const TripCalendar = () => {
	const [events, setEvents] = useState([]);
	const [tripList, { data }] = useLazyQuery(GET_TRIP_LIST);
	const calendarRef = useRef<FullCalendar | null>(null);
	const navigate = useNavigate();
	const handleViewDidMount = (view: any) => {
		const dateFrom = view.startStr;
		const dateTo = view.endStr;
		const timestamp = new Date().getTime().toString();
		tripList({ variables: { dateFrom, dateTo, timestamp } });
	};
	useEffect(() => {
		if (!data) return;
		const formattedFoEvents = data.tripList.map((trip: ITripData) => {
			const status: StatusesEnum = getTripStatus(trip);
			const color: StatusColorsRGB = getTripStatusColor(status);
			return {
				id: trip._id,
				title: `${trip.name} (${trip.car?.registrationNo})`,
				start: formatDate(+trip.dateFrom),
				end: formatDate(+trip.dateTo, true),
				color
			};
		});
		setEvents(formattedFoEvents);
	}, [data]);
	const formatDate = (timestamp: number, isEnd: boolean = false) => {
		const date = new Date(timestamp);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const checkDay = isEnd ? 1 : 0;
		const day = String(date.getDate() + checkDay).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};
	const newTrip = (info: any) => {
		let dateTo = new Date(info.end.getTime());
		dateTo.setDate(info.end.getDate() - 1);
		const offset = dateTo.getTimezoneOffset();
		dateTo = new Date(dateTo.getTime() - offset * 60 * 1000);
		navigate({
			pathname: '/trip/new',
			search: createSearchParams({
				dateFrom: info.startStr,
				dateTo: dateTo.toISOString().split('T')[0]
			}).toString()
		});
	};
	return (
		<Container maxW={'8xl'}>
			<FullCalendar
				ref={calendarRef}
				locale="pl"
				plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
				initialView="dayGridMonth"
				datesSet={handleViewDidMount}
				selectable={true}
				events={events}
				eventClick={function (info) {
					const event = info.event;
					navigate(`/trip/${event.id}`);
				}}
				select={newTrip}
				headerToolbar={{
					left: 'prev,next today',
					center: 'title',
					right: 'dayGridMonth,dayGridWeek,dayGridDay,list'
				}}
			/>
		</Container>
	);
};

export default TripCalendar;
