import { createContext, useState, useContext } from 'react';

interface TripData {
	_id?: string | number;
	step: number;
	agency: string;
	name: string;
	dateFrom: Date | null;
	dateTo: Date | null;
	people: number | null;
	car: string;
	driver: string;
	notes: string | null | undefined;
	days: ITripDay[];
	distance: string | null;
}

interface ITripDay {
	day: Date;
	notes?: String;
	hotel?: String;
	distance?: String;
	files: string[] | null;
	plan: ITripPlan[];
}

interface ITripPlan {
	allDay: boolean;
	start: string;
	end?: string;
	place: string;
	address: string;
	lat: string;
	long: string;
	placeId: string;
}

interface TripContextType {
	tripData: TripData;
	setTripData: React.Dispatch<React.SetStateAction<TripData>>;
}

const TripContext = createContext<TripContextType>({} as TripContextType);

export const useTripContext = () => useContext(TripContext);

export const TripProvider = ({ children }: { children: any }) => {
	const [tripData, setTripData] = useState<TripData>({
		_id: 0,
		step: 0,
		agency: '',
		name: '',
		dateFrom: null,
		dateTo: null,
		people: null,
		car: '',
		driver: '',
		notes: '',
		days: [],
		distance: null
	});

	return (
		<TripContext.Provider value={{ tripData, setTripData }}>
			{children}
		</TripContext.Provider>
	);
};
