import { Flex, Heading } from '@chakra-ui/react';

interface IProps {
	title: string;
	actions?: any;
}

export default function PageHeader({ title, actions }: IProps) {
	return (
		<Flex justifyContent={'space-between'} alignItems={'center'} w={'full'}>
			<Heading>{title}</Heading>
			{actions}
		</Flex>
	);
}
