import { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
	Card,
	CardBody,
	Flex,
	FormControl,
	FormLabel,
	Input
} from '@chakra-ui/react';
import { ITripPlan } from '../../../../types/ITrip';

interface IProps {
	item: ITripPlan;
	destinations: any[];
	setDestinations: any;
	planItemIndex: number;
	updatePlanItem: any;
	indexOfDay: any;
}

export default function TripPlanItem({
	item,
	destinations,
	setDestinations,
	planItemIndex,
	updatePlanItem,
	indexOfDay
}: IProps) {
	const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
	useEffect(() => {
		if (inputRef.current) {
			const options: any = {
				language: 'pl'
			};
			const autoComplete = new window.google.maps.places.Autocomplete(
				inputRef.current,
				options
			);
			google.maps.event.addListener(autoComplete, 'place_changed', () => {
				const place = autoComplete.getPlace();
				if (place) {
					if (place.geometry?.viewport || place.geometry?.location) {
						const lat = place.geometry?.location?.lat();
						const lng = place.geometry?.location?.lng();
						const newDestination = [...destinations];
						newDestination.push({
							lat,
							lng
						});
						setDestinations(newDestination);
						const { formatted_address, name } = place;
						const item: ITripPlan = {
							start: '',
							place: name || '',
							address: formatted_address || '',
							description: '',
							lat,
							lng
						};
						updatePlanItem(item, planItemIndex);
					}
				}
			});
		}
	}, [destinations]);
	const [time, setTime] = useState<string | null>(null); // Define the type of 'time'
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value;
		setTime(val);
		updatePlanItem({ ...item, ...{ start: val } }, planItemIndex);
	};
	useEffect(() => {
		const val = `${item.place}, ${item.address}`;
		if (inputRef.current)
			inputRef.current.value =
				inputRef.current && val !== '' && val !== ', ' ? val : '';
		setTime(item.start);
	}, [planItemIndex, indexOfDay]);
	return (
		<Card>
			<CardBody>
				<Flex flexFlow={'column'} gap={5}>
					<FormControl>
						<FormLabel>Miejsce</FormLabel>
						<Input ref={inputRef} />
					</FormControl>
					<FormControl>
						<FormLabel>Godzina wyjazdu</FormLabel>
						<Input type="time" value={time || ''} onChange={handleChange} />
					</FormControl>
				</Flex>
			</CardBody>
		</Card>
	);
}
