import { gql } from '@apollo/client';

export const ADD_CAR = gql`
	mutation addCar(
		$brand: String!
		$carModel: String!
		$registrationNo: String!
		$vin: String
		$seats: Int
		$yearOfProduction: Int
		$engine: String
	) {
		addCar(
			input: {
				brand: $brand
				carModel: $carModel
				registrationNo: $registrationNo
				vin: $vin
				seats: $seats
				yearOfProduction: $yearOfProduction
				engine: $engine
			}
		) {
			id
		}
	}
`;
