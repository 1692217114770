export enum StatusesEnum {
	Draft,
	Confirmed,
	Active,
	Done
}

export enum StatusesNamesEnum {
	Draft = 'Wersja robocza',
	Confirmed = 'Potwierdzono',
	Active = 'W trakcie',
	Done = 'Zakończony'
}
