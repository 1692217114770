import {
	FormLabel,
	VStack,
	Text,
	FormControl,
	StackDivider
} from '@chakra-ui/react';

interface IProps {
	data: IStackList[];
}

interface IStackList {
	key: string;
	value: string;
}

export default function StackList({ data }: IProps) {
	return (
		<VStack
			divider={<StackDivider borderColor="gray.200" />}
			spacing={4}
			align="stretch">
			{data.map((el: IStackList, i: number) => (
				<FormControl key={i} py={1}>
					<FormLabel fontWeight={500}>{el.key}</FormLabel>
					<Text color={'gray.500'}>{el.value || '-'}</Text>
				</FormControl>
			))}
		</VStack>
	);
}
