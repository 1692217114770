import { gql } from '@apollo/client';

export const UPDATE_CAR = gql`
	mutation updateCar(
		$id: ID!
		$brand: String!
		$carModel: String!
		$registrationNo: String!
		$vin: String
		$seats: Int
		$yearOfProduction: Int
		$engine: String
	) {
		updateCar(
			id: $id
			input: {
				brand: $brand
				carModel: $carModel
				registrationNo: $registrationNo
				vin: $vin
				seats: $seats
				yearOfProduction: $yearOfProduction
				engine: $engine
			}
		)
	}
`;
