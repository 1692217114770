import {
	Text,
	Flex,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	useDisclosure,
	DrawerHeader,
	DrawerBody,
	DrawerFooter,
	Button
} from '@chakra-ui/react';
import { Fragment, useEffect } from 'react';
import { LuAlertCircle } from 'react-icons/lu';

interface IProps {
	item: any;
	value: any;
	title: string;
	element: any;
	onClear: () => void;
	onSave: (el: any) => void;
}

export default function TableRequired({
	item,
	value,
	title,
	element,
	onClear,
	onSave
}: IProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	useEffect(() => {
		onClear();
	}, [isOpen]);
	const save = () => {
		onSave(item);
		onClose();
	};
	if (!value)
		return (
			<Fragment>
				<Flex
					justifyContent={'start'}
					alignItems={'center'}
					gap={1}
					cursor={'pointer'}
					onClick={onOpen}>
					<Text textDecoration={'underline'} fontSize={'sm'}>
						Uzupełnij dane
					</Text>
					<LuAlertCircle color="red" />
				</Flex>
				<Drawer onClose={onClose} isOpen={isOpen} size={'lg'}>
					<DrawerOverlay />
					<DrawerContent>
						<DrawerHeader borderBottomWidth="1px">{title}</DrawerHeader>
						<DrawerBody bg={'gray.100'} pt={5}>
							{element}
						</DrawerBody>
						<DrawerFooter borderTopWidth="1px">
							<Button variant="outline" mr={3} onClick={onClose}>
								Anuluj
							</Button>
							<Button colorScheme="blue" onClick={save}>
								Zapisz
							</Button>
						</DrawerFooter>
					</DrawerContent>
				</Drawer>
			</Fragment>
		);
	return <>{value}</>;
}
