import { Flex, Text } from '@chakra-ui/react';
import { LuArrowLeft } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

interface IProps {
	page: string;
	name: string;
}

export default function BackBreadcrumb({ page, name }: IProps) {
	const navigate = useNavigate();
	const goToPage = () => {
		navigate(`/${page}`);
	};
	return (
		<Flex
			gap={2}
			alignItems={'center'}
			fontWeight={500}
			color={'blackAlpha.800'}
			onClick={goToPage}>
			<LuArrowLeft fontSize={'1.5rem'} />
			<Text cursor={'pointer'} _hover={{ textDecoration: 'underline' }}>
				{name}
			</Text>
		</Flex>
	);
}
