import { Card, CardBody, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { parseDate } from '../../../../utils/parseDate';

interface IProps {
	numberOfDay: number;
	date: Date | null;
	distance: number;
	setActiveDayIndex: any;
	isActive: boolean;
}

export default function TripPlanDay({
	numberOfDay,
	date,
	distance,
	setActiveDayIndex,
	isActive
}: IProps) {
	const [currentDay, setCurrentDay] = useState('');
	useEffect(() => {
		if (date) {
			const parsed = parseDate(date);
			setCurrentDay(parsed);
		}
	}, [date]);
	const setDay = () => {
		setActiveDayIndex(numberOfDay);
	};
	return (
		<Card
			onClick={setDay}
			cursor={'pointer'}
			border={'1px solid'}
			borderColor={isActive ? '#2a4365' : 'transparent'}
			boxShadow={isActive ? 'xl' : 'md'}>
			<CardBody border={'none'}>
				<Flex justifyContent={'space-between'} alignItems={'center'}>
					<Flex flexFlow={'column'}>
						<Text fontSize={'md'} fontWeight={600}>
							Dzień {numberOfDay + 1}
						</Text>
						<Text fontSize={'sm'} color={'gray.500'} fontWeight={400}>
							{currentDay}
						</Text>
					</Flex>
					<Text fontWeight={600} color={'gray.600'}>
						{Math.ceil(distance / 1000)} km
					</Text>
				</Flex>
			</CardBody>
		</Card>
	);
}
