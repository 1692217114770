import { Flex, Heading, Badge, Text } from '@chakra-ui/react';
import Avatar from '../avatar/Avatar';

interface IProps {
	id: string;
	title: string;
	image?: string;
	typeModel: string;
}

export default function DetailsHeading({
	id,
	title,
	image,
	typeModel
}: IProps) {
	return (
		<Flex justifyContent={'space-between'} alignItems={'center'}>
			<Flex alignItems={'center'} justifyContent={'start'} gap={5}>
				<Avatar image={image} alt={title} id={id} typeModel={typeModel} />
				<Flex flexFlow={'column'} gap={3}>
					<Heading>{title}</Heading>
					<Flex alignItems={'center'}>
						<Text fontSize="sm" fontWeight={'500'} color={'gray.900'}>
							_id:
						</Text>
						<Badge
							ml="1"
							fontSize=".9em"
							bgColor={'gray.200'}
							borderRadius={10}
							px={2}>
							{id}
						</Badge>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
}
