import { gql } from '@apollo/client';

export const ADD_DRIVER = gql`
	mutation registerDriver(
		$firstName: String!
		$lastName: String!
		$phone: String
		$email: String
	) {
		registerDriver(
			input: {
				firstName: $firstName
				lastName: $lastName
				phone: $phone
				email: $email
			}
		) {
			_id
		}
	}
`;
