// src/graphql/queries/GetPostsQuery.ts
import { gql } from '@apollo/client';

export const GET_CAR = gql`
	query getCar($id: ID!) {
		getCar(id: $id) {
			brand
			carModel
			seats
			registrationNo
			vin
			yearOfProduction
			engine
			seatAdjustment
			audioVideo
			avatar
		}
	}
`;
