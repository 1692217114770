import { Skeleton, Text } from '@chakra-ui/react';
import SelectList from '../selectList';
import { useQuery } from '@apollo/client';
import { GET_TRIP_DRIVERS } from '../../graphql/queries/GetTripDrivers';
import {
	Ref,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState
} from 'react';

interface IProps {
	dateFrom: string;
	dateTo: string;
	onSelect: any;
	activeItem: any;
}

interface RefObject {
	getList: () => any;
}

const TripDriverList = forwardRef(
	({ dateFrom, dateTo, onSelect, activeItem }: IProps, ref: Ref<RefObject>) => {
		useImperativeHandle(ref, () => ({ getList }));
		const [selected, setSelected] = useState<string>('');
		const [list, setList] = useState([] as any[]);
		const { loading, data } = useQuery(GET_TRIP_DRIVERS, {
			variables: {
				dateFrom,
				dateTo
			}
		});
		useEffect(() => {
			setSelected(activeItem);
		}, [activeItem]);
		useEffect(() => {
			if (data?.tripDrivers) {
				const arr = [] as any[];
				data.tripDrivers.map((driver: any) => {
					arr.push({
						id: driver._id,
						title: driver.Name,
						subTitles: [],
						available: driver.isAvailable,
						note: driver.note
					});
				});
				const sortedArr = arr.sort(
					(a: any, b: any) => b.available - a.available
				);
				setList(sortedArr);
			}
		}, [data]);
		const getList = () => {
			return list.find((e: any) => e.id === selected);
		};
		const setActive = (id: string) => {
			setSelected(id);
			onSelect(id);
		};
		if (loading) return <Skeleton></Skeleton>;
		if (!loading && list.length === 0) return <Text>Brak danych</Text>;
		return (
			<SelectList
				title="Wybierz kierowcę"
				values={list}
				active={selected}
				setActive={setActive}
			/>
		);
	}
);

export default TripDriverList;
