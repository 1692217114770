// src/graphql/queries/GetPostsQuery.ts
import { gql } from '@apollo/client';

export const CHECK_TOKEN = gql`
query {
	me {
		userName
		firstName
		lastName
		email
		phone
		RoleName
	}
}
`;