import { Box, Button, Flex } from '@chakra-ui/react';
import { useTripContext } from '../../../context/TripContext';
import TripDriverList from '../driverList';
import TripVehicleList from '../vehicleList';
import { useEffect, useState } from 'react';
import { LuArrowRight } from 'react-icons/lu';

interface IProps {
	setPage: any;
	mode: string;
}

const TripVehicleAndDriver = ({ setPage, mode }: IProps) => {
	const { tripData, setTripData } = useTripContext();
	const [dates, setDates] = useState() as any;
	useEffect(() => {
		if (tripData.dateFrom && tripData.dateTo) {
			const offset = tripData.dateFrom.getTimezoneOffset();
			const dateFrom = new Date(
				tripData.dateFrom.getTime() - offset * 60 * 1000
			)
				.toISOString()
				.split('T')[0];
			const dateTo = new Date(tripData.dateTo.getTime() - offset * 60 * 1000)
				.toISOString()
				.split('T')[0];
			setDates({
				dateFrom,
				dateTo
			});
		}
	}, [tripData]);
	const goBack = () => {
		const s = tripData.step === 1 && mode === 'driver' ? 0 : 1;
		setTripData({ ...tripData, ...{ step: s } });
		setPage(false);
	};
	const checkValidDriver = (): boolean => {
		return tripData.driver !== '';
	};
	const checkValidVehicle = (): boolean => {
		return tripData.car !== '';
	};
	const goNext = () => {
		if (
			(tripData.step === 1 && mode === 'driver' && checkValidDriver()) ||
			(tripData.step === 2 && mode === 'vehicle' && checkValidVehicle())
		)
			openNextPage();
	};
	const openNextPage = () => {
		const step: number = tripData.step + 1;
		setTripData({ ...tripData, ...{ step } });
		setPage();
	};
	const onSetDriver = (id: string) => {
		setTripData({ ...tripData, ...{ driver: id } });
	};
	const onSetVehicle = (id: string) => {
		setTripData({ ...tripData, ...{ car: id } });
	};
	if (tripData.step < 1 || tripData.step > 2 || !dates) return <></>;
	return (
		<Box
			display={
				(tripData.step === 1 && mode === 'driver') ||
				(tripData.step === 2 && mode === 'vehicle')
					? 'block'
					: 'none'
			}
			minW={'300px'}
			maxW={'520px'}
			w={'100%'}>
			{tripData.step === 1 && mode === 'driver' ? (
				<TripDriverList
					dateFrom={dates.dateFrom}
					dateTo={dates.dateTo}
					onSelect={onSetDriver}
					activeItem={tripData.driver}
				/>
			) : tripData.step === 2 && mode === 'vehicle' ? (
				<TripVehicleList
					dateFrom={dates.dateFrom}
					dateTo={dates.dateTo}
					onSelect={onSetVehicle}
					activeItem={tripData.car}
				/>
			) : (
				''
			)}
			<Flex mt={8} justifyContent={'space-between'} alignItems={'center'}>
				<Flex gap={2}>
					<Button
						isDisabled={
							tripData.step === 1 && mode === 'driver'
								? !checkValidDriver()
								: !checkValidVehicle()
						}
						rightIcon={<LuArrowRight />}
						colorScheme={'blue'}
						onClick={goNext}>
						Dalej
					</Button>
					<Button colorScheme={'red'} onClick={goBack} variant={'ghost'}>
						Cofnij
					</Button>
				</Flex>
				<Button onClick={openNextPage} variant={'ghost'}>
					Pomiń
				</Button>
			</Flex>
		</Box>
	);
};

export default TripVehicleAndDriver;
