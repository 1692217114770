import { Box, Card, CardBody, SimpleGrid } from '@chakra-ui/react';
import { ICar } from '../../types/ICar';
import StackList from '../../components/stackList/StackList';
import { useEffect, useState } from 'react';

interface IProps {
	car: ICar;
}

export default function VehicleDetailsTab({ car }: IProps) {
	const [list, setList] = useState<any[]>([]);
	useEffect(() => {
		const {
			brand,
			carModel,
			vin,
			seats,
			yearOfProduction,
			engine,
			registrationNo
		} = car;
		setList([
			{ key: 'Rejestracja', value: registrationNo },
			{ key: 'Marka', value: brand },
			{ key: 'carModel', value: carModel },
			{ key: 'VIN', value: vin },
			{ key: 'Liczba miejsc', value: seats },
			{ key: 'Rok produkcji', value: yearOfProduction },
			{ key: 'Silnik', value: engine }
		]);
	}, [car]);
	return (
		<SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
			<Box>
				<Card>
					<CardBody>
						<StackList data={list} />
					</CardBody>
				</Card>
			</Box>
			<Box></Box>
		</SimpleGrid>
	);
}
