import { Td, Flex } from '@chakra-ui/react';
import { LuEdit2, LuTrash2, LuArrowRight } from 'react-icons/lu';
import ButtonHover from '../controls/ButtonHover';

interface IProps {
	el: any;
	onEdit: any;
	onDelete: any;
	onOpen: any;
}

export default function TableActions({ el, onEdit, onDelete, onOpen }: IProps) {
	return (
		<Td color={'gray.500'}>
			<Flex justifyContent={'flex-end'} gap={1} fontSize={'xl'}>
				{onEdit ? (
					<ButtonHover icon={<LuEdit2 />} click={() => onEdit(el)} />
				) : (
					''
				)}
				{onDelete ? (
					<ButtonHover icon={<LuTrash2 />} click={() => onDelete(el)} />
				) : (
					''
				)}
				{onOpen ? (
					<ButtonHover icon={<LuArrowRight />} click={() => onOpen(el)} />
				) : (
					''
				)}
			</Flex>
		</Td>
	);
}
