import { DateRangeResult } from '../enums/Dates';
import { StatusesEnum } from '../enums/Statuses';
import { ITrip, ITripData } from '../types/ITrip';
import checkDateRange from './checkDateRange';

export default function getTripStatus(trip: ITrip | ITripData): StatusesEnum {
	if (trip.status.toLowerCase() === 'done') return StatusesEnum.Done;
	else if (trip.status.toLowerCase() === 'active') return StatusesEnum.Active;
	else if (
		trip.status.toLowerCase() === 'confirmed' ||
		trip.status.toLowerCase() === 'draft'
	) {
		const dateStatus: DateRangeResult = checkDateRange(
			trip.dateFrom,
			trip.dateTo
		);
		if (DateRangeResult.Before === dateStatus) {
			if (trip.status.toLowerCase() === 'confirmed')
				return StatusesEnum.Confirmed;
			else return StatusesEnum.Draft;
		} else if (DateRangeResult.Between === dateStatus)
			return StatusesEnum.Active;
		else return StatusesEnum.Done;
	} else return StatusesEnum.Draft;
}
