import { useSteps, Hide, Flex, Image, Heading, Box } from '@chakra-ui/react';
import TripData from '../../components/trip/steps/tripData';
import { TripProvider } from '../../context/TripContext';
import TripVehicleAndDriver from '../../components/trip/steps/vehicleAndDriver';
// import TripDays from '../../components/trip/days';
import trip1 from '../../assets/bus-trip-1.webp';
import trip2 from '../../assets/bus-trip-2.webp';
import trip3 from '../../assets/bus-trip-3.webp';
import TripStep from '../../components/trip/tripStep';
import TripDays from '../../components/trip/steps/tripDays';
// import { useJsApiLoader } from '@react-google-maps/api';

const TripNew = () => {
	const images = [trip1, trip2, trip3];
	const index: number = Math.floor(Math.random() * 3);
	const image: string = images[index];
	const { activeStep, setActiveStep } = useSteps({
		index: 0,
		count: 4
	});
	const setPage = (nextPage = true) => {
		const step = nextPage ? activeStep + 1 : activeStep - 1;
		setActiveStep(step);
	};
	return (
		<Flex>
			<Hide below="md">
				<Flex flex={5} display={activeStep > 2 ? 'none' : 'flex'}>
					<Image
						src={image}
						alt="New trip image"
						height={'calc(100vh - 64px)'}
						marginTop={'-64px'}
						width={'full'}
						position={'sticky'}
					/>
				</Flex>
			</Hide>
			<Flex
				flexFlow={'column'}
				flex={8}
				height={'calc(100vh - 128px)'}
				maxHeight={'calc(100vh - 128px)'}
				p={{ base: '32px', md: '48px', xl: '64px' }}
				pt={{ base: 0, md: 0, xl: 0 }}>
				<TripProvider>
					<Heading mt={'-32px'} mb={'24px'}>
						Nowy wyjazd
					</Heading>
					<Box overflowY={'auto'} display={activeStep > 2 ? 'none' : 'block'}>
						<TripStep step={1} title="Dane">
							<TripData setPage={setPage} />
						</TripStep>
						<TripStep step={2} title="Kierowca">
							<TripVehicleAndDriver setPage={setPage} mode="driver" />
						</TripStep>
						<TripStep step={3} title="Pojazd">
							<TripVehicleAndDriver setPage={setPage} mode="vehicle" />
						</TripStep>
					</Box>
					<Box display={activeStep > 2 ? 'block' : 'none'}>
						<TripDays />
					</Box>
				</TripProvider>
			</Flex>
		</Flex>
	);
};

export default TripNew;
