import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
	mutation updateUser(
		$id: ID!
		$firstName: String!
		$lastName: String!
		$phone: String
		$email: String
	) {
		updateUser(
			_id: $id
			input: {
				firstName: $firstName
				lastName: $lastName
				phone: $phone
				email: $email
			}
		)
	}
`;
