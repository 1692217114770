// src/graphql/queries/GetPostsQuery.ts
import { gql } from '@apollo/client';

export const GET_DASHBOARD = gql`
query {
	getDashboard {
        busCount
        driverCount
        agencyCount
	}
}
`;