import React, { Fragment, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_TRIP } from '../../graphql/queries/GetTrip';
import Loading from '../../components/loading/Loading';
import { Flex, Heading } from '@chakra-ui/react';
import { TripProvider } from '../../context/TripContext';
import UpdateTripPlan from '../../components/trip/steps/tripPlan/update';

const TripUpdate: React.FC = () => {
	const navigate = useNavigate();
	const params = useParams();
	const id = params.id;
	const { loading, error, data } = useQuery(GET_TRIP, {
		variables: { id }
	});
	useEffect(() => {
		if (data) if (data.trip === null) navigate('/');
	}, [data]);
	if (loading) return <Loading />;
	if (error) navigate('/');
	return (
		<Fragment>
			<Flex
				flexFlow={'column'}
				flex={8}
				height={'calc(100vh - 128px)'}
				maxHeight={'calc(100vh - 128px)'}
				p={{ base: '32px', md: '48px', xl: '64px' }}>
				<TripProvider>
					<Heading mt={'-32px'} mb={'24px'}>
						Edycja wyjazdu
					</Heading>
					<UpdateTripPlan trip={data.trip} />
				</TripProvider>
			</Flex>
		</Fragment>
	);
};

export default TripUpdate;
