import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';

const AgencyForm = ({ register }: { register: any }) => {
	return (
		<Flex flexFlow={'column'} gap={3}>
			<FormControl isRequired>
				<FormLabel>Nazwa</FormLabel>
				<Input {...register('name')} />
			</FormControl>
			<FormControl>
				<FormLabel>NIP</FormLabel>
				<Input {...register('nip')} />
			</FormControl>
			<FormControl>
				<FormLabel>Adres</FormLabel>
				<Input {...register('address')} />
			</FormControl>
			<FormControl>
				<FormLabel>Kod pocztowy</FormLabel>
				<Input {...register('postalCode')} />
			</FormControl>
			<FormControl>
				<FormLabel>Miasto</FormLabel>
				<Input {...register('city')} />
			</FormControl>
			<FormControl>
				<FormLabel>Kraj</FormLabel>
				<Input {...register('country')} />
			</FormControl>
			<FormControl>
				<FormLabel>E-mail</FormLabel>
				<Input {...register('email')} />
			</FormControl>
			<FormControl>
				<FormLabel>Telefon</FormLabel>
				<Input {...register('phone')} />
			</FormControl>
			<FormControl>
				<FormLabel>Przedstawiciel (imię i nazwisko)</FormLabel>
				<Input {...register('person')} />
			</FormControl>
		</Flex>
	);
};

export default AgencyForm;
