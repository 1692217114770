// src/graphql/queries/GetPostsQuery.ts
import { gql } from '@apollo/client';

export const GET_TRIP_DRIVERS = gql`
  query tripDrivers($dateFrom: String!, $dateTo: String!) {
    tripDrivers(dateFrom: $dateFrom, dateTo: $dateTo) {
        _id
        Name
        isAvailable
        note
    }
  }
`;