import { gql } from '@apollo/client';

export const GET_TRIP = gql`
	query trip($id: ID!) {
		trip(id: $id) {
			_id
			status
			name
			dateFrom
			dateTo
			pilot
			pilotPhone
			driver {
				firstName
				lastName
				phone
				email
			}
			car {
				brand
				carModel
				registrationNo
			}
			agency {
				name
				phone
				email
				person
			}
			days {
				_id
				day
				hotel
				notes
				distance
				plan {
					_id
					start
					place
					address
					lat
					lng
				}
			}
		}
	}
`;
