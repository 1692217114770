import { StatusColorsRGB } from '../enums/StatusColors';
import { StatusesEnum } from '../enums/Statuses';

export function getTripStatusColor(status: StatusesEnum): StatusColorsRGB {
	switch (status) {
		case StatusesEnum.Draft:
			return StatusColorsRGB.Draft;
			break;
		case StatusesEnum.Confirmed:
			return StatusColorsRGB.Confirmed;
			break;
		case StatusesEnum.Active:
			return StatusColorsRGB.Active;
			break;
		case StatusesEnum.Done:
			return StatusColorsRGB.Done;
			break;
	}
}
