// src/graphql/queries/GetPostsQuery.ts
import { gql } from '@apollo/client';

export const GET_TRIP_LIST = gql`
	query tripList(
		$dateFrom: String
		$dateTo: String
		$carID: String
		$agencyID: String
		$driverID: String
	) {
		tripList(
			dateFrom: $dateFrom
			dateTo: $dateTo
			carID: $carID
			agencyID: $agencyID
			driverID: $driverID
		) {
			_id
			name
			status
			agency {
				name
			}
			car {
				_id
				registrationNo
			}
			driver {
				_id
				firstName
				lastName
			}
			dateFrom
			dateTo
		}
	}
`;
