import { FormControl, Input, Flex, FormLabel, Button } from '@chakra-ui/react';

const PersonForm = ({ register }: { register: any }) => {
	return (
		<Flex flexFlow={'column'} gap={'1rem'}>
			<Flex gap={'1rem'}>
				<FormControl>
					<FormLabel>Imię</FormLabel>
					<Input placeholder="Imię" {...register('firstName')} />
				</FormControl>
				<FormControl>
					<FormLabel>Nazwisko</FormLabel>
					<Input placeholder="Nazwisko" {...register('lastName')} />
				</FormControl>
			</Flex>
			<FormControl>
				<FormLabel>Telefon</FormLabel>
				<Input placeholder="Telefon" {...register('phone')} />
			</FormControl>
			<FormControl>
				<FormLabel>Email</FormLabel>
				<Input placeholder="Email" {...register('email')} />
			</FormControl>
			<Flex justifyContent={'end'}>
				<Button colorScheme="blue" width={{ base: 'full', md: 'auto' }}>
					Zapisz
				</Button>
			</Flex>
		</Flex>
	);
};

export default PersonForm;
