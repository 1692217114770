import { Flex, Icon, Text } from '@chakra-ui/react';

const LinkItem = ({ name, icon }: { name: string; icon: any | null }) => {
	return (
		<Flex
			align="center"
			fontWeight={600}
			py={2}
			px={3}
			borderRadius="lg"
			role="group"
			cursor="pointer"
			_hover={{
				bg: 'blue.800',
				color: 'gray.50'
			}}>
			{icon ? (
				<Icon
					fontSize={'xl'}
					_groupHover={{
						color: 'gray.50'
					}}
					as={icon}
				/>
			) : (
				''
			)}
			<Text ml={icon ? 4 : 8} fontWeight={icon ? 600 : 300}>
				{name}
			</Text>
		</Flex>
	);
};

export default LinkItem;
