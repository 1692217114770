import {
	FormControl,
	FormLabel,
	Flex,
	Input,
	Skeleton,
	Textarea
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { GET_AGENCY_LIST } from '../../graphql/queries/GetAgencyList';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

interface IOption {
	value: string;
	label: string;
}

const statuses: IOption[] = [
	{ label: 'Wersja robocza', value: 'Draft' },
	{ label: 'Potwierdzony', value: 'Confirmed' }
];

const TripForm = ({ register, setValue }: { register: any; setValue: any }) => {
	const { data, loading } = useQuery(GET_AGENCY_LIST);
	const [options, setOptions] = useState([] as IOption[]);
	const setAgency = (e: any) => {
		setValue('agency', e.value);
	};
	const setStatus = (e: any) => {
		setValue('status', e.value);
	};
	useEffect(() => {
		if (!loading) updateOptions();
	}, [loading]);
	const updateOptions = () => {
		const op: IOption[] = [];
		data.agencyList.forEach((el: { id: string; name: string }) => {
			op.push({
				value: el.id,
				label: el.name
			});
		});
		setOptions(op);
	};
	return (
		<Skeleton isLoaded={!loading}>
			<Flex flexFlow={'column'} gap={4}>
				<FormControl isRequired>
					<FormLabel>Status</FormLabel>
					<Select useBasicStyles options={statuses} onChange={setStatus} />
				</FormControl>
				<FormControl isRequired>
					<FormLabel>Organizator</FormLabel>
					<Skeleton isLoaded={!loading}>
						<Select useBasicStyles options={options} onChange={setAgency} />
					</Skeleton>
				</FormControl>
				<FormControl isRequired>
					<FormLabel>Nazwa</FormLabel>
					<Input {...register('name')} />
				</FormControl>
				<FormControl isRequired>
					<FormLabel>Data wyjazdu</FormLabel>
					<Input id="tipDateFrom" type="date" {...register('dateFrom')} />
				</FormControl>
				<FormControl isRequired>
					<FormLabel>Data zakończenia</FormLabel>
					<Input id="tipDateTo" type="date" {...register('dateTo')} />
				</FormControl>
				<FormControl isRequired>
					<FormLabel>Liczba osób</FormLabel>
					<Input type="number" min={0} {...register('people')} />
				</FormControl>
				<FormControl>
					<FormLabel>Pilot</FormLabel>
					<Input {...register('pilot')} />
				</FormControl>
				<FormControl>
					<FormLabel>Kontakt do pilota</FormLabel>
					<Input {...register('pilotPhone')} />
				</FormControl>
				<FormControl>
					<FormLabel>Uwagi</FormLabel>
					<Textarea {...register('notes')} />
				</FormControl>
			</Flex>
		</Skeleton>
	);
};

export default TripForm;
