// src/AuthProvider.tsx
import React, { createContext, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { AuthContextType, AuthProviderProps } from '../types';
import { CHECK_TOKEN } from '../graphql/queries/CheckToken';
import Loading from '../components/loading/Loading';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [token, setToken] = useState<string | null>(
		localStorage.getItem('token') || null
	);

	const { loading, error, data } = useQuery(CHECK_TOKEN, {
		variables: { token },
		skip: !token
	});

	const isAuthenticated = !loading && !error && data?.me;

	const user = {
		userName: isAuthenticated ? data?.me.userName : '',
		firstName: isAuthenticated ? data?.me.firstName : '',
		lastName: isAuthenticated ? data?.me.lastName : '',
		phone: isAuthenticated ? data?.me.phone : '',
		email: isAuthenticated ? data?.me.email : '',
		roleName: isAuthenticated ? data?.me.RoleName : ''
	};

	const login = (newToken: string) => {
		localStorage.setItem('token', newToken);
		setToken(newToken);
	};

	const logout = () => {
		localStorage.removeItem('token');
		setToken(null);
	};

	const authContextValue: AuthContextType = {
		firstName: user.firstName,
		lastName: user.lastName,
		phone: user.phone,
		email: user.email,
		roleName: user.roleName,
		isAuthenticated,
		login,
		logout
	};

	if (loading) return <Loading />;

	if (error) return <div>Error: {error.message}</div>;

	return (
		<AuthContext.Provider value={authContextValue}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const authContext = useContext(AuthContext);
	if (!authContext) {
		console.log(authContext);
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return authContext;
};

export default AuthProvider;
